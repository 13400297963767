import styled from '@emotion/styled';
import { PHYHUB_DEVICES_OVERVIEW_STATUSES } from '../../../../../store/types/phyhub-devices';

const COLUMNS_AMOUNT = PHYHUB_DEVICES_OVERVIEW_STATUSES.length + 3;

export const GridBase = styled.div`
  display: grid;
  grid-template-columns: repeat(${COLUMNS_AMOUNT}, 1fr);
  column-gap: 20px;
  font-style: normal;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
`;

export const TableCell = styled.div<{ align?: 'center' | 'left' | 'right' }>`
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: ${({ align = 'left' }) => {
    switch (align) {
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  }};
  align-items: center;
`;
