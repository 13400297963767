import React, { FC, useMemo } from 'react';
import useEnvironments from '../../../../use-environments/use-environments';
import { Spin } from 'antd';

interface PhyhubDevicesTableEnvironmentCellContainerProps {
  environmentName: string;
  tenantId: string;
}

const PhyhubDevicesTableEnvironmentCellContainer: FC<PhyhubDevicesTableEnvironmentCellContainerProps> = (
  props,
) => {
  const { environmentName, tenantId } = props;

  const {
    isLoading: isEnvironmentsLoading,
    data: environmentsCollection = [],
  } = useEnvironments(tenantId);

  const environmentDisplayName = useMemo<string>(() => {
    const environmentItem = environmentsCollection.find(
      (environment) => environment.environmentName === environmentName,
    );

    return environmentItem ? environmentItem.displayName : environmentName;
  }, [environmentName, environmentsCollection]);

  if (isEnvironmentsLoading) {
    return <Spin size="small" />;
  }

  return <>{environmentDisplayName}</>;
};

export default PhyhubDevicesTableEnvironmentCellContainer;
