import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import OrganisationApp from '../../../../store/types/organisation-app';
import PhyhubDevicesInstallationItem from '../phyhub-devices-installation-item/phyhub-devices-installation-item.component';

interface PhyhubDevicesInstallationsListProps {
  tenantId: string;
  installations: OrganisationApp[];
}

const PhyhubDevicesInstallationsList: FC<PhyhubDevicesInstallationsListProps> = (
  props,
) => {
  const { tenantId, installations } = props;

  const { t } = useTranslation();

  const columns = useMemo<ColumnProps<OrganisationApp>[]>(
    () => [
      {
        key: 'installationTypeImage',
        title: t('installation'),
        align: 'left',
        render: (_, installation) => (
          <PhyhubDevicesInstallationItem
            tenantId={tenantId}
            installation={installation}
          />
        ),
      },
      {
        key: 'installationSlug',
        title: t('installationName'),
        dataIndex: 'displayName',
      },
    ],
    [tenantId, t],
  );

  return (
    <Table<OrganisationApp>
      rowKey="id"
      columns={columns}
      dataSource={installations}
      pagination={false}
    />
  );
};

export default PhyhubDevicesInstallationsList;
