import React, { FC } from 'react';
import { Icon, Popover } from 'antd';
import styled from '@emotion/styled';
import PhyhubDevicesTableSettingsActions, {
  PhyhubDevicesSettingsAction,
} from './phyhub-devices-table-settings-actions/phyhub-devices-table-settings-actions.component';
import { PhyhubDevice } from '../../../../../../services/phyhub/types/phyhub-device.interface';

interface PhyhubDevicesSettingsCellProps {
  deviceScreenshotUrl: string | null;
  actionItems: PhyhubDevicesSettingsAction[];
  deviceItem: PhyhubDevice;
}

const PhyhubDevicesSettingsCell: FC<PhyhubDevicesSettingsCellProps> = (props) => {
  const { deviceScreenshotUrl, actionItems, deviceItem } = props;

  return (
    <Wrapper>
      {deviceScreenshotUrl ? (
        <Popover
          key={`${deviceItem.id}-screenshot`}
          content={<ScreenshotImage alt="" src={deviceScreenshotUrl} />}
          overlayStyle={{
            maxWidth: '250px',
            maxHeight: '200px',
          }}
        >
          <WithScreenshotIcon type="eye" />
        </Popover>
      ) : (
        <WithoutScreenShotIcon type="eye-invisible" />
      )}
      {actionItems.length > 0 && (
        <PhyhubDevicesTableSettingsActions
          deviceItem={deviceItem}
          actionItems={actionItems}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const ScreenshotImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const WithScreenshotIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.85);
`;

const WithoutScreenShotIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.45);
`;

export default PhyhubDevicesSettingsCell;
