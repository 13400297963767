import React, { FC, memo } from 'react';
import { ColumnProps, TableRowSelection } from 'antd/lib/table';
import PaginatedListTable from '../../../paginated-list/paginatied-list-table';
import { PaginationCollection } from '../../../../../store/types/pagination';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';

interface PhyhubDevicesTableProps {
  isFetching: boolean;
  columns: ColumnProps<PhyhubDevice>[];
  paginationCollection: PaginationCollection<PhyhubDevice>;
  rowSelection?: TableRowSelection<PhyhubDevice>;
}

const PhyhubDevicesTable: FC<PhyhubDevicesTableProps> = (props) => {
  const { isFetching, columns, paginationCollection, rowSelection } = props;

  return (
    <PaginatedListTable<PhyhubDevice>
      isDataLoading={isFetching}
      columns={columns}
      data={paginationCollection}
      rowSelection={rowSelection}
    />
  );
};

export default memo(PhyhubDevicesTable);
