import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PhyhubDevicesBaseActionModal from '../phyhub-devices-base-action-modal/phyhub-devices-base-action-modal.component';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';

interface PhyhubDevicesRebootModalProps {
  isVisible: boolean;
  devices: PhyhubDevice[];
  onCancel: () => void;
  onRebootConfirm: (deviceIds: string[]) => void;
}

const PhyhubDevicesRebootModal: FC<PhyhubDevicesRebootModalProps> = (props) => {
  const { isVisible, devices, onRebootConfirm } = props;

  const { t } = useTranslation();

  const handleOk = useCallback(
    (selectedDeviceIds: string[]) => {
      onRebootConfirm(selectedDeviceIds);
    },
    [onRebootConfirm],
  );

  return (
    <PhyhubDevicesBaseActionModal
      isVisible={isVisible}
      cancelText={t('phyhubDevices.modal.rebootDevices.cancelText')}
      okText={t('phyhubDevices.modal.rebootDevices.okText')}
      title={t('phyhubDevices.modal.rebootDevices.title')}
      affectedDevices={devices}
      skipperDevices={[]}
      onCancel={props.onCancel}
      onOk={handleOk}
    />
  );
};

export default memo(PhyhubDevicesRebootModal);
