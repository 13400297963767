import React, { useMemo } from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { DataMatrixCard } from '@ombori/grid-reports';
import { Link } from 'react-router-dom';
import CardContainer from '../card-container';
import { prepareDateRangeSearchParams } from '../../utils';
import {
  AnalyticsSchemaAwareReportForDataMatrixInstallation,
  DataMatrixDataForInstallation,
} from '../../../use-tenant-analytics-report/types';

interface AnalyticsReportDataMatrixInstallationCardProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  card: DataMatrixCard;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: AnalyticsSchemaAwareReportForDataMatrixInstallation | null;
}

const AnalyticsReportDataMatrixInstallationCard = (props: AnalyticsReportDataMatrixInstallationCardProps) => {
  const { tenantId, dateFrom, dateTo, card, isLoading, isSuccess, isError, data } = props;

  const tableColumnsData = useMemo(() => {
    if (!data) {
      return [];
    }
  
    return data.cardData.tableColumnsData.filter(({ dataColumns }) => {
      return Object.keys(dataColumns).some((key) => {
        const value = dataColumns[key];
        return typeof value === 'number' && value > 0;
      });
    });
  }, [data]);  

  const searchParams = useMemo(() => {
    return prepareDateRangeSearchParams(dateFrom, dateTo);
  }, [dateFrom, dateTo]);

  const tableColumns = useMemo<ColumnProps<DataMatrixDataForInstallation>[]>(() => {
    // get first element from tableColumns to get dynamic columns (dataColumns)
    // as all the records have the same columns regardless of whether they've data or not
    const firstRowDataColumns = tableColumnsData[0] ? tableColumnsData[0].dataColumns : {};

    const dataColumnsForTable = Object.keys(firstRowDataColumns).map<
      ColumnProps<DataMatrixDataForInstallation>
    >((columnKey, index) => {
      return {
        key: `${columnKey}:${index}`,
        title: columnKey,
        sorter: (source, target) => {
          const sourceValue = source.dataColumns[columnKey];
          const targetValue = target.dataColumns[columnKey];

          if (typeof sourceValue === 'number' && typeof targetValue === 'number') {
            return sourceValue - targetValue;
          } else {
            return (sourceValue as string).localeCompare(targetValue as string);
          }
        },
        render: (_, record) => {
          return record.dataColumns[columnKey];
        },
      };
    });

    return [
      {
        key: 'installation',
        title: 'Installation',
        sorter: (source, target) => {
          return source.displayName.localeCompare(target.displayName);
        },
        render: (_, record) => {
          return (
            <Link
              key={`installation-link-${record.id}`}
              to={{
                pathname: `/organisations/${tenantId}/apps/installations/${record.id}`,
                search: `${searchParams}`,
              }}
            >
              {record.displayName}
            </Link>
          );
        },
      },
      ...dataColumnsForTable,
    ];
  }, [tableColumnsData, searchParams, tenantId]);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={tableColumnsData.length ? true : false}
      title={card.title}
      gridStyles={card.gridStyles}
      isVisibleWithoutData={card.isVisibleWithoutData}
    >
      <Table
        columns={tableColumns}
        dataSource={tableColumnsData}
        size="middle"
        pagination={{
          pageSize: 5,
        }}
        scroll={{ x: 'max-content' }}
      />
    </CardContainer>
  );
};

export default AnalyticsReportDataMatrixInstallationCard;
