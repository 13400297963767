import usePhyhubDeviceDeleteFlow from './phyhub-device-actions/use-phyhub-device-delete-flow';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PhyhubDevicesSettingsAction } from '../phyhub-devices-list/phyhub-devices-table/phyhub-devices-table-settings-cell/phyhub-devices-table-settings-actions/phyhub-devices-table-settings-actions.component';
import { PhyhubDevice } from '../../../../services/phyhub/types/phyhub-device.interface';
import { PhyhubDeviceStatusEnum } from '../../../../services/phyhub/enums/phyhub-device-status.enum';
import usePhyhubDeviceRebootFlow from './phyhub-device-actions/use-phyhub-device-reboot-flow';

interface UsePhyhubDevicesDefaultDeviceActionParams {
  tenantId: string;
}

interface UsePhyhubDevicesDefaultDeviceActionReturn {
  defaultDeviceActionItems: PhyhubDevicesSettingsAction[];
}

const usePhyhubDevicesDefaultDeviceActions = (
  params: UsePhyhubDevicesDefaultDeviceActionParams,
): UsePhyhubDevicesDefaultDeviceActionReturn => {
  const { tenantId } = params;

  const { t } = useTranslation();

  const { handleDeviceDelete } = usePhyhubDeviceDeleteFlow({ tenantId });

  const { handleDeviceReboot } = usePhyhubDeviceRebootFlow({ tenantId });

  const deviceActionItems = useMemo<PhyhubDevicesSettingsAction[]>(
    () => [
      {
        getDisabled: (device: PhyhubDevice) =>
          device.status !== PhyhubDeviceStatusEnum.ONLINE,
        icon: 'poweroff',
        title: t('rebootDevice'),
        getKey: (device: PhyhubDevice) => `${device.id}_reboot_device`,
        onClick: handleDeviceReboot,
      },
      {
        icon: 'delete',
        title: t('deleteDevice'),
        getKey: (device: PhyhubDevice) => `${device.id}_delete_device`,
        style: { color: '#f5222d' },
        onClick: handleDeviceDelete,
      },
    ],
    [handleDeviceDelete, handleDeviceReboot, t],
  );

  return {
    defaultDeviceActionItems: deviceActionItems,
  };
};

export default usePhyhubDevicesDefaultDeviceActions;
