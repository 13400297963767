import React, { useMemo } from 'react';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Legend,
  CartesianGrid,
} from 'recharts';
import numeral from 'numeral';
import dayjs from 'dayjs';
import upperFirst from 'lodash/upperFirst';
import identity from 'lodash/identity';
import get from 'lodash/get';
import Card from './card.component';
import LabeledValue from './labeled-value.component';
import { sumDays, sumEvents, getWowChange, getDodChange, Day } from '../utils';
import { getColor } from './colors';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { transform } from 'lodash';

const sentenceCase = (string: string) =>
  upperFirst(string.toLowerCase().replace(/[_]/g, ' '));

export default function LineCard({
  title,
  label,
  info,
  data,
  precedingData,
  stacked = false,
  children,
  showCard = true,
  labelFormatter = identity,
  isLegendVisible = false,
  avgChartData,
}: {
  title: string;
  data: Day[];
  precedingData?: Day[];
  label?: string;
  info?: string;
  stacked?: boolean;
  children?: React.ReactNode;
  showCard?: boolean;
  labelFormatter?: (label: string | number) => string | number;
  isLegendVisible?: boolean;
  avgChartData: {
    name: string;
    thisPeriod: number;
    previousPeriod: number;
  }[];
}) {
  const { t } = useTranslation();

  const newData = data.map((item, index) => ({
    currentPeriodData: item,
    precedingPeriodData: precedingData ? precedingData[index] : [],
  }));

  const dataKeys = [t('currentPeriod'), t('precedingPeriod')];

  const lineChartData = newData.map(({ currentPeriodData, precedingPeriodData }) => {
    const [date, events] = currentPeriodData;

    const result: any = {
      label: date,
      'Current period': sumEvents(events),
    };

    if (precedingPeriodData.length > 0) {
      const [, precedingEvents] = precedingPeriodData;
      result['Preceding period'] = sumEvents(precedingEvents);
    }

    return result;
  });

  const footer = useMemo(() => {
    const wowChange = getWowChange(data);
    const dodChange = getDodChange(data);

    return (
      <>
        {wowChange && (
          <LabeledValue
            label={t('wowChange')}
            value={`${wowChange}%`}
            flag={wowChange >= 0 ? t('up') : t('down')}
          />
        )}
        {dodChange && (
          <LabeledValue
            label={t('dodChange')}
            value={`${dodChange}%`}
            flag={dodChange >= 0 ? t('up') : t('down')}
          />
        )}
      </>
    );
  }, [data]);

  const renderElement = (
    <>
      {children || (
        <div style={{ flex: 1 }}>
          <LabeledValue
            style={{ marginBottom: 16 }}
            size="md"
            label={label || `Total ${title.toLowerCase()}`}
            value={sumDays(data)}
          />
        </div>
      )}
      <ResponsiveContainer height={300}>
        <LineChart
          data={lineChartData}
          margin={{ top: -20, right: 0, left: 10, bottom: 0 }}
        >
          {isLegendVisible && (
            <Legend
              verticalAlign="top"
              align="right"
              height={40}
              wrapperStyle={{ fontSize: 12, transform: 'translateY(-10px)' }}
            />
          )}
          <XAxis
            dataKey="label"
            tickFormatter={(str) => dayjs(str).format('DD MMM')}
            interval="preserveStartEnd" // Prevent excessive ticks
            tickMargin={10} // Adjust spacing between axis and ticks
            tick={{ fontSize: 12, fill: '#666' }}
            stroke="#ccc"
          />
          <YAxis
            width={40}
            orientation="right"
            tickFormatter={(value) => numeral(value).format('0a')}
            allowDecimals={false}
            tick={{ fontSize: 12, fill: '#666' }}
            stroke="#ccc"
          />
          <Tooltip
            labelFormatter={(label) => `Date: ${label}`}
            formatter={(value, name) => [
              numeral(value).format('0.00a'),
              sentenceCase(name),
            ]}
            contentStyle={{ fontSize: 12 }}
          />

          <Line
            type="monotone"
            dataKey="Preceding period" // Matches the key in lineChartData
            stroke="#ccc"
            strokeWidth={2}
            activeDot={{ r: 8 }}
            name="Preceding period"
          />

          {/* Render the current period last to ensure it's on top */}
          <Line
            type="monotone"
            dataKey="Current period" // Matches the key in lineChartData
            stroke="#3b82f6"
            strokeWidth={2}
            activeDot={{ r: 8 }}
            name="Current period"
          />

          <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
        </LineChart>
      </ResponsiveContainer>

      {/* Horizontal Bar Chart */}
      <ChartContainer>
        <ChartTitle>{t('averageEventPerSession')}</ChartTitle>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            layout="vertical"
            data={avgChartData} // Use avgChartData directly
            barSize={20}
            barCategoryGap={5}
            margin={{ top: 5, right: 30, bottom: 0, left: -20 }}
          >
            <XAxis
              type="number"
              domain={[
                0,
                Math.ceil(
                  Math.max(
                    ...avgChartData.map((item) =>
                      Math.max(item.thisPeriod || 0, item.previousPeriod || 0),
                    ),
                  ) * 1.2,
                ), // Ensure a fallback max value and round up
              ]}
              tick={{ fontSize: 12, fill: '#666' }}
              allowDecimals={false}
              stroke="#ccc"
            />
            <YAxis
              width={30}
              type="category"
              dataKey="name"
              tick={false} // Hide Y-axis labels
              stroke="#ccc"
            />
            <Tooltip
              cursor={{ fill: 'rgba(0, 0, 0, 0)' }} // Highlight the hovered bar
              formatter={(value, name) => [`${value}`, name]} // Show exact value
              contentStyle={{ fontSize: 12 }}
            />
            <Bar
              dataKey="thisPeriod"
              fill="#3772e0"
              name="This Period"
              radius={[0, 2, 2, 0]}
            />
            {avgChartData.some((item) => item.previousPeriod > 0) && (
              <Bar
                dataKey="previousPeriod"
                fill="#ccc"
                name="Previous Period"
                radius={[0, 2, 2, 0]}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </ChartContainer>

      {/* {footer} */}
    </>
  );

  return (
    <>
      {showCard ? (
        <Card title={title} info={info}>
          {renderElement}
        </Card>
      ) : (
        renderElement
      )}
    </>
  );
}

const ChartContainer = styled.div`
  width: 100%;
  height: 80px;
  margin: 20px 0 40px 0;
`;
const ChartTitle = styled.p`
  margin: 0;
  padding: 0 0 0 10px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
`;
