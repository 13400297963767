import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { Tabs } from 'antd';
import { CardType, AnalyticsSchema, Card } from '@ombori/grid-reports';
import { useStoreState } from '../../../../store/initialize-store';
import ErrorView from '../../error-view/error-view.component';
import Organisation from '../../../../store/types/organisation';
import { AppEventsList } from '../cards/events-list';
import { AppSessions } from '../cards/sessions';
import { AppEventsFlow } from '../cards/events-flow';
import { AppNps } from '../cards/nps';
import { AppProductsEventCount } from '../cards/products-event-count';
import { AppCategoriesEventCount } from '../cards/categories-event-count';
import { AppEventsFunnel } from '../cards/events-funnel';
import { AppWeekHeatmap } from '../cards/week-heatmap';
import { AppEventsCount } from '../cards/events-count';
import { AppAverageSales } from '../cards/average-sales';
import { AppAverageTimeBetweenTransactions } from '../cards/average-time-between-transactions';
import { AppAverageNumberOfPurchasedProducts } from '../cards/average-number-of-purchased-products';
import { AppPurchasedProductsCategoriesEventCount } from '../cards/purchased-products-categories-event-count';
import { AppPurchasedProductsEventCount } from '../cards/purchased-products-event-count';
import { AppQrCodesEventCount } from '../cards/qr-codes-event-count';
import { AppMedia } from '../cards/media';
import AnalyticsReport from '../analytics-report';
import Controls from '../controls';
import RangePicker from '../../range-picker/range-picker.component';
import useDateRange from '../../use-date-range';
import ErrorBoundary from '../../error-boundary/error-boundary.component';
import DataMatrix from '../cards/data-matrix';
import { useAnalyticsParams } from '../../use-analytics-params';

const { TabPane } = Tabs;

const Container = styled.div``;

const WrapperContainer = styled.div``;

interface ReportProps {
  schema: AnalyticsSchema;
  tenant: Organisation;
  appId: string;
}

const Report: React.FC<ReportProps> = ({ schema: { groups }, tenant, appId }) => {
  const { dateFrom, dateTo, onChangeRange } = useDateRange();

  const { updateAnalyticsParams } = useAnalyticsParams();

  useEffect(() => {
    updateAnalyticsParams({ fromDate: dateFrom, toDate: dateTo });
  }, [dateFrom, dateTo, updateAnalyticsParams]);

  const getComponent = useCallback(
    (reportCard: Card): React.ReactNode => {
      switch (reportCard.type) {
        case CardType.EventsList:
          return (
            <AppEventsList
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsCount:
          return (
            <AppEventsCount
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              eventType={reportCard.eventType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Sessions:
          return (
            <AppSessions
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsFlow:
          return (
            <AppEventsFlow
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Nps:
          return (
            <AppNps
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.ProductsEventCount:
          return (
            <AppProductsEventCount
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              eventType={reportCard.eventType}
              title={reportCard.title}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.CategoriesEventCount:
          return (
            <AppCategoriesEventCount
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsFunnel:
          return (
            <AppEventsFunnel
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              events={reportCard.events}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.WeekHeatmap:
          return (
            <AppWeekHeatmap
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              dataSource={reportCard.dataSource}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.DataMatrix:
          return (
            <DataMatrix
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              dataSource={reportCard.dataSource}
              columns={reportCard.columns}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageSales:
          return (
            <AppAverageSales
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageTimeBetweenTransactions:
          return (
            <AppAverageTimeBetweenTransactions
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageNumberOfPurchasedProducts:
          return (
            <AppAverageNumberOfPurchasedProducts
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.PurchasedProductsEventCount:
          return (
            <AppPurchasedProductsEventCount
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.PurchasedProductsCategoriesEventCount:
          return (
            <AppPurchasedProductsCategoriesEventCount
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.QrCodesCount:
          return (
            <AppQrCodesEventCount
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Media:
          return (
            <AppMedia
              tenantId={tenant.id}
              appId={appId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
              primaryKey={reportCard.primaryKey}
            />
          );

        case CardType.ModulesStatus:
          return <></>;

        default:
          // @ts-ignore
          throw new Error(`Unknown schema card type ${reportCard.type}`);
      }
    },
    [tenant, appId, dateFrom, dateTo],
  );

  return (
    <WrapperContainer>
      <Controls>
        <RangePicker dateFrom={dateFrom} dateTo={dateTo} onChangeRange={onChangeRange} />
      </Controls>

      <Tabs defaultActiveKey="1" animated={false}>
        {groups.map((group, index) => {
          const components = group.cards.map(getComponent);

          return (
            <TabPane tab={group.name} key={`${index + 1}`}>
              <AnalyticsReport
                cards={components}
                columnsCount={group.columnsCount}
                gridStyles={group.gridStyles}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </WrapperContainer>
  );
};

interface AppReportProps {
  schema: AnalyticsSchema;
}

const AppReport: React.FC<AppReportProps> = ({ schema }) => {
  const { organisationId: tenantId, gridappId } = useParams<{
    organisationId: string;
    gridappId: string;
  }>();

  const { tenant } = useStoreState(
    (state) => ({
      tenant: state.organisations.data && state.organisations.data[tenantId],
    }),
    [tenantId],
  );

  if (!tenant) {
    return <ErrorView />;
  }

  return (
    <Container>
      <ErrorBoundary>
        <Report tenant={tenant} schema={schema} appId={gridappId} />
      </ErrorBoundary>
    </Container>
  );
};

export default AppReport;
