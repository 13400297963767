import { useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';
import phyhubDevicesQueryKeys from './phyhub-devices-query-keys';
import { usePhyhubService } from '../../../app/common/phyhub-service-provider/phyhub-service.provider';
import { PhyhubDevicesBulkUpdatePayload } from '../../../services/phyhub/types/phyhub-devices-bulk-update-payload.interface';

export interface PhyhubDevicesBulkUpdateMutationParams {
  tenantId: string;
  payload: PhyhubDevicesBulkUpdatePayload;
}

const usePhyhubDevicesBulkUpdate = () => {
  const phyhubService = usePhyhubService();
  const queryClient = useQueryClient();

  return useMutation(
    ({ tenantId, payload }: PhyhubDevicesBulkUpdateMutationParams) =>
      phyhubService.bulkUpdateDevices(
        {
          tenantId,
        },
        payload,
      ),
    {
      onSuccess: (_, { tenantId }) =>
        queryClient.invalidateQueries(phyhubDevicesQueryKeys.manyPrefixKeys(tenantId)),
      onError: (error) => {
        Sentry.captureException(error);
      },
    },
  );
};

export default usePhyhubDevicesBulkUpdate;
