import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import PhyhubDeviceStatus, {
  PhyhubDeviceStatusThemeEnum,
} from '../../../phyhub-device-status/phyhub-device-status';
import { GridBase, TableCell } from '../phyhub-devices-overview-common.components';
import { PhyhubDeviceStatusesBreakdown } from '../../../../../../services/phyhub/types/phyhub-device-statuses-breakdown.interface';
import {
  PHYHUB_DEVICE_STATUS_TRANSLATION_MAP,
  PHYHUB_DEVICES_OVERVIEW_STATUSES,
} from '../../../../../../store/types/phyhub-devices';

interface PhyhubDevicesSummaryTableHeaderProps {
  isToggleOpen: boolean;
  isToggleVisible: boolean;
  summaryBreakdown: PhyhubDeviceStatusesBreakdown;
  onToggleClick: () => void;
}

const PhyhubDevicesOverviewTableHeader: FC<PhyhubDevicesSummaryTableHeaderProps> = (
  props,
) => {
  const { isToggleOpen, isToggleVisible, summaryBreakdown } = props;

  const { t } = useTranslation();

  return (
    <TableHeader>
      <TableCell>
        {t('phyhubDevices.summary.total', { count: summaryBreakdown.total })}
      </TableCell>
      <TableCell />
      {PHYHUB_DEVICES_OVERVIEW_STATUSES.map((status) => {
        const statusCount = summaryBreakdown.statuses.find(({ type }) => type === status);

        return (
          <TableCell key={`summary_header_${status}`}>
            <PhyhubDeviceStatus status={status} theme={PhyhubDeviceStatusThemeEnum.LARGE}>
              {statusCount ? statusCount.count : 0}{' '}
              {t(PHYHUB_DEVICE_STATUS_TRANSLATION_MAP[status])}
            </PhyhubDeviceStatus>
          </TableCell>
        );
      })}
      {isToggleVisible && (
        <TableCell align="center">
          <ToggleDevicesLink onClick={props.onToggleClick}>
            <Icon type={isToggleOpen ? 'up' : 'down'} />
            {t('phyhubDevices.toggleBreakdown')}
          </ToggleDevicesLink>
        </TableCell>
      )}
    </TableHeader>
  );
};

const TableHeader = styled(GridBase)`
  margin: 0 0 4px 0;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid transparent;
`;

const ToggleDevicesLink = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #1890ff;
`;

export default memo(PhyhubDevicesOverviewTableHeader);
