import { PhyhubDevicesListQueryParams } from './use-phyhub-devices-list';
import { PhyhubDevicesGetQueryParams } from './use-phyhub-devices-get';
import { PhyhubDevicesOverviewQueryParams } from './use-phyhub-devices-overview';

const phyhubDevicesQueryKeys = {
  queryPrefix: () => {
    return 'phyhub-devices';
  },
  tenantPrefixKeys: (tenantId: string) => [
    phyhubDevicesQueryKeys.queryPrefix(),
    'tenantId',
    tenantId,
  ],
  manyPrefixKeys: (tenantId: string) => [
    ...phyhubDevicesQueryKeys.tenantPrefixKeys(tenantId),
    'many',
  ],
  singlePrefixKeys: (tenantId: string) => [
    ...phyhubDevicesQueryKeys.tenantPrefixKeys(tenantId),
    'single',
  ],
  getPhyhubDevicesListKeys: (
    phyhubDevicesListQueryParams: PhyhubDevicesListQueryParams,
  ) => [
    ...phyhubDevicesQueryKeys.manyPrefixKeys(phyhubDevicesListQueryParams.tenantId),
    'list',
    'page',
    phyhubDevicesListQueryParams.page,
    'limit',
    phyhubDevicesListQueryParams.limit,
    'filters',
    JSON.stringify(phyhubDevicesListQueryParams.filters || {}),
    'sort',
    JSON.stringify(phyhubDevicesListQueryParams.sort || {}),
    'search',
    phyhubDevicesListQueryParams.search,
  ],
  getPhyhubDevicesGetKeys: (phyhubDevicesGetQueryParams: PhyhubDevicesGetQueryParams) => [
    ...phyhubDevicesQueryKeys.singlePrefixKeys(phyhubDevicesGetQueryParams.tenantId),
    'get',
    'deviceId',
    phyhubDevicesGetQueryParams.deviceId,
  ],
  getPhyhubDevicesOverviewKeys: (
    phyhubDevicesGetReportQueryParams: PhyhubDevicesOverviewQueryParams,
  ) => [
    ...phyhubDevicesQueryKeys.manyPrefixKeys(phyhubDevicesGetReportQueryParams.tenantId),
    'overview',
    'filters',
    JSON.stringify(phyhubDevicesGetReportQueryParams.filters || {}),
    'search',
    phyhubDevicesGetReportQueryParams.search,
  ],
};

export default phyhubDevicesQueryKeys;
