import React, { useEffect, useState } from 'react';
import ContentWrap from '../../../../common/app-layout/content/content-wrap.component';
import {
  AnalyticsSchema,
  CardType,
  DataMatrixColumnDataType,
  DataMatrixDataSource,
  InteractionType,
  SessionInteractionType,
  WeekHeatmapDataSourceType,
} from '@ombori/grid-reports';
import styled from '@emotion/styled';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import TenantAnalyticsSchemaAwareReport from '../../../../common/overview-report/reports/tenant-analytics-schema-aware-report';

const Container = styled(ContentWrap)`
  padding-top: 0;
`;

const TenantAnalyticsSchemaAwareReportContainer = () => {
  const [showReport, setShowReport] = useState(false);

  const reportSchema: AnalyticsSchema = {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
          { type: CardType.EventsList, interactionType: InteractionType.Interactive },
          {
            type: CardType.EventsFunnel,
            title: 'Sales conversions funnel',
            events: ['CATEGORY_VIEW', 'PRODUCT_VIEW', 'CART_ADD', 'CHECKOUT', 'PURCHASE'],
          },
          {
            type: CardType.ProductsEventCount,
            eventType: 'PRODUCT_VIEW',
            title: 'Product views',
          },
          { type: CardType.CategoriesEventCount },
          {
            type: CardType.DataMatrix,
            title: 'Performance by location',
            dataSource: DataMatrixDataSource.Space,
            columns: [
              {
                title: 'Total sessions',
                dataType: {
                  type: DataMatrixColumnDataType.Sessions,
                },
              },
              {
                title: 'Total events',
                dataType: {
                  type: DataMatrixColumnDataType.Events,
                },
              },
            ],
          },
          {
            type: CardType.DataMatrix,
            title: 'Performance by installation',
            dataSource: DataMatrixDataSource.Installation,
            columns: [
              {
                title: 'Total sessions',
                dataType: {
                  type: DataMatrixColumnDataType.Sessions,
                },
              },
              {
                title: 'Total events',
                dataType: {
                  type: DataMatrixColumnDataType.Events,
                },
              },
            ],
          },
        ],
      },
      {
        name: 'Events flow',
        cards: [{ type: CardType.EventsFlow }],
        columnsCount: 1,
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowReport(true);
    }, 500);
    return () => {
      setShowReport(false);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Container>
      {!showReport && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
      {showReport && <TenantAnalyticsSchemaAwareReport schema={reportSchema} />}
    </Container>
  );
};

export default TenantAnalyticsSchemaAwareReportContainer;
