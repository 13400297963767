import React, { FC, useCallback } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { Icon } from '../../../../../schema-form/common';
import { PhyhubDevice } from '../../../../../../../services/phyhub/types/phyhub-device.interface';

export interface PhyhubDevicesSettingsAction {
  getDisabled?: (device: PhyhubDevice) => boolean;
  icon?: string;
  title: string;
  getKey: (device: PhyhubDevice) => string;
  style?: React.CSSProperties;
  onClick: (device: PhyhubDevice) => void;
}

interface PhyhubDevicesTableSettingsActionsProps {
  deviceItem: PhyhubDevice;
  actionItems: PhyhubDevicesSettingsAction[];
}

const PhyhubDevicesTableSettingsActions: FC<PhyhubDevicesTableSettingsActionsProps> = (
  props,
) => {
  const { deviceItem, actionItems } = props;

  const getClickHandler = useCallback(
    (onClick: (device: PhyhubDevice) => void) => () => {
      onClick(deviceItem);
    },
    [deviceItem],
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu>
          {actionItems.map((item) => (
            <Menu.Item key={item.getKey(deviceItem)} style={item.style}>
              <Button
                disabled={item.getDisabled && item.getDisabled(deviceItem)}
                type="link"
                icon={item.icon}
                size="small"
                style={item.style}
                onClick={getClickHandler(item.onClick)}
              >
                {item.title}
              </Button>
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Icon type="small-dash" />
    </Dropdown>
  );
};

export default PhyhubDevicesTableSettingsActions;
