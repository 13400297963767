import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'antd';
import usePhyhubDeviceStatusColor from '../phyhub-device-status/hooks/use-phyhub-device-status-color';
import { PhyhubDeviceStatusEnum } from '../../../../services/phyhub/enums/phyhub-device-status.enum';
import { PhyhubDeviceStatusColorEnum } from '../phyhub-device-status/phyhub-device-status';

interface PhyhubDeviceCardProps {
  deviceDisplayName: string;
  deviceScreenshotUrl?: string | null;
  deviceStatus: PhyhubDeviceStatusEnum;
}

const phyhubDeviceStatusBackgroundColorEnum: { [status: string]: string } = {
  [PhyhubDeviceStatusEnum.OFFLINE]: '#fff1f0',
  [PhyhubDeviceStatusEnum.ONLINE]: 'rgba(245, 246, 250, 0.8)',
};

const PhyhubDeviceCard: React.FC<PhyhubDeviceCardProps> = (props) => {
  const { deviceDisplayName, deviceStatus, deviceScreenshotUrl } = props;

  const deviceScreenshotBackgroundColor =
    phyhubDeviceStatusBackgroundColorEnum[deviceStatus] ||
    phyhubDeviceStatusBackgroundColorEnum[PhyhubDeviceStatusEnum.OFFLINE];

  return (
    <CoverContainer color={deviceScreenshotBackgroundColor}>
      {deviceScreenshotUrl ? (
        <ScreenshotImage alt={deviceDisplayName} src={deviceScreenshotUrl} />
      ) : (
        <PlaceholderContainer>
          <PlaceholderIcon type="picture" />
        </PlaceholderContainer>
      )}
    </CoverContainer>
  );
};

const CoverContainer = styled.div<{ color: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: ${({ color }) => color};
  margin-bottom: 16px;
  height: 300px;
  border-radius: 8px;
`;

const ScreenshotImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 160px;
`;

const PlaceholderIcon = styled(Icon)`
  font-size: 80px;
  opacity: 0.1;
`;

export default PhyhubDeviceCard;
