import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import moment from 'moment';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import { useModalsManager } from '../../../modals-manager-provider/modals-manager.provider';
import PhyhubDevicesUpdateOsModal from '../../phyhub-devices-modals/phyhub-devices-update-os-modal/phyhub-devices-update-os-modal.component';
import { PhyhubDeviceVersion } from '../../../../../services/phyhub/types/phyhub-device-version.interface';

// TODO: Replace mocked data with real data when API is ready

const MOCKED_PHYHUB_DEVICE_VERSIONS: PhyhubDeviceVersion[] = [
  { version: '2.0.0', latest: false },
  { version: '2.1.5', latest: false },
  { version: '2.2.5', latest: false },
  { version: '2.2.5', latest: false },
  { version: '2.5.0', latest: true },
];

const MOCKED_DEFAULT_PHYHUB_DEVICE_VERSION = '2.5.0';

const MODAL_KEY = 'phyhubDevicesUpdateOs';

interface UsePhyhubDevicesUpdateOsFlowParams {
  tenantId: string;
  onMassActionSuccess: () => void;
}

interface UsePhyhubDevicesUpdateOsFlowReturn {
  handleUpdateOs: (selectedDevices: PhyhubDevice[]) => void;
}

const usePhyhubDevicesUpdateOsFlow = (
  params: UsePhyhubDevicesUpdateOsFlowParams,
): UsePhyhubDevicesUpdateOsFlowReturn => {
  const { tenantId, onMassActionSuccess } = params;

  const { t } = useTranslation();

  const [devicesForUpdateOs, setDevicesForUpdateOs] = useState<PhyhubDevice[]>([]);

  const isUpdateOsAvailable = useMemo<boolean>(() => {
    const uniqueOsVersions = Array.from(
      new Set(
        devicesForUpdateOs.map((device) =>
          device.os ? `${device.os.type}_${device.os.arch}` : null,
        ),
      ).values(),
    );

    return uniqueOsVersions.length === 1 && uniqueOsVersions[0] !== null;
  }, [devicesForUpdateOs]);

  const handleUpdateOs = useCallback((selectedDevices: PhyhubDevice[]) => {
    setDevicesForUpdateOs(selectedDevices);
  }, []);

  const handleUpdateOsConfirm = useCallback(
    (
      deviceIds: string[],
      plannedTimestamp: moment.Moment,
      targetDeviceVersion: string,
    ) => {
      setDevicesForUpdateOs([]);

      // TODO: Implement device OS updating logic when API is ready
      console.log('Update OS Confirm', {
        tenantId,
        targetDeviceVersion,
        plannedTimestamp,
        selectedDeviceIds: deviceIds,
      });

      onMassActionSuccess();
    },
    [tenantId, onMassActionSuccess],
  );

  const handleUpdateOsReset = useCallback(() => {
    setDevicesForUpdateOs([]);
  }, []);

  useEffect(() => {
    if (!!devicesForUpdateOs.length && !isUpdateOsAvailable) {
      Modal.info({
        title: t('phyhubDevices.modal.updateOsNotAvailable.title'),
        content: t('phyhubDevices.modal.updateOsNotAvailable.content'),
        okText: t('phyhubDevices.button.done'),
        onOk: handleUpdateOsReset,
      });
    }
  }, [isUpdateOsAvailable, devicesForUpdateOs.length, t, handleUpdateOsReset]);

  const modalNode = useMemo(
    () => (
      <PhyhubDevicesUpdateOsModal
        isVisible={!!devicesForUpdateOs.length && isUpdateOsAvailable}
        defaultTargetVersion={MOCKED_DEFAULT_PHYHUB_DEVICE_VERSION}
        availableVersions={MOCKED_PHYHUB_DEVICE_VERSIONS}
        devices={devicesForUpdateOs}
        onCancel={handleUpdateOsReset}
        onUpdateOsConfirm={handleUpdateOsConfirm}
      />
    ),
    [devicesForUpdateOs, isUpdateOsAvailable, handleUpdateOsConfirm, handleUpdateOsReset],
  );

  const { addModal, removeModal } = useModalsManager();

  useEffect(() => {
    addModal({
      key: MODAL_KEY,
      node: modalNode,
    });

    return () => {
      removeModal(MODAL_KEY);
    };
  }, [addModal, modalNode, removeModal]);

  return {
    handleUpdateOs,
  };
};

export default usePhyhubDevicesUpdateOsFlow;
