export enum PhyhubDeviceListParamsFiltersOperatorEnum {
  IN = 'in',
  NOT_IN = 'nin',
}

export interface PhyhubDeviceListParamsFilters {
  [key: string]: {
    op: PhyhubDeviceListParamsFiltersOperatorEnum;
    val: string[];
  };
}

export type PhyhubDeviceListParamsSort = Partial<
  Record<'displayName' | 'createdAt' | 'updatedAt', 'asc' | 'desc'>
>;

export interface PhyhubDeviceListParams {
  tenantId: string;
  limit?: number;
  page?: number;
  search?: string;
  filters?: PhyhubDeviceListParamsFilters;
  sort?: PhyhubDeviceListParamsSort;
}
