import { useQuery } from 'react-query';
import phyhubDevicesQueryKeys from './phyhub-devices-query-keys';
import { usePhyhubService } from '../../../app/common/phyhub-service-provider/phyhub-service.provider';

export interface PhyhubDevicesGetQueryParams {
  tenantId: string;
  deviceId: string;
}

const usePhyhubDevicesGet = (
  phyhubDevicesGetQueryParams: PhyhubDevicesGetQueryParams,
) => {
  const phyhubService = usePhyhubService();

  return useQuery(
    phyhubDevicesQueryKeys.getPhyhubDevicesGetKeys(phyhubDevicesGetQueryParams),
    () =>
      phyhubService.getDevice({
        deviceId: phyhubDevicesGetQueryParams.deviceId,
        tenantId: phyhubDevicesGetQueryParams.tenantId,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default usePhyhubDevicesGet;
