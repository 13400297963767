import * as React from 'react';
import {
  useStoreActions,
  useStoreState,
} from '../../../../../../../store/initialize-store';
import { useActiveTenantAllSpaces } from '../../../../../../common/spaces-provider/spaces-provider.component';
import { SetupPhyhubDeviceFormValues } from './setup-phyhub-device-form.component';
import SetupPhyhubDevice from './setup-phyhub-device.component';
import usePhyhubDevicesCreate from '../../../../../../../store/hooks/phyhub-devices/use-phyhub-devices-create';

type SetupPhyhubDeviceContainerProps = Omit<
  React.ComponentProps<typeof SetupPhyhubDevice>,
  | 'fetchEnvironments'
  | 'createDevice'
  | 'environments'
  | 'app'
  | 'spaces'
  | 'fetchSpaces'
  | 'user'
>;

const SetupPhyhubDeviceContainer = (props: SetupPhyhubDeviceContainerProps) => {
  const {
    match: {
      params: { organisationId, appId },
    },
  } = props;

  const { app, environments, user } = useStoreState(
    (state) => ({
      app:
        (state.organisationApps.data &&
          state.organisationApps.data[organisationId] &&
          state.organisationApps.data[organisationId][appId]) ||
        null,
      environments: state.environments.values(organisationId),
      user: state.auth.user,
    }),
    [organisationId],
  );

  const { spaces } = useActiveTenantAllSpaces();

  const { mutateAsync: phyhubDeviceCreate } = usePhyhubDevicesCreate();

  const { fetchEnvironments, fetchSpaces } = useStoreActions((actions) => ({
    fetchEnvironments: () => {
      return actions.environments.fetch({ organizationId: organisationId });
    },
    fetchSpaces: () => {
      return actions.organisationSpaces.fetch({
        organizationId: organisationId,
        silent: true,
      });
    },
  }));

  const handleCreateDevice = React.useCallback(
    async (createDeviceFormValues: SetupPhyhubDeviceFormValues) => {
      await phyhubDeviceCreate({
        tenantId: organisationId,
        payload: {
          installation: { id: appId },
          env: createDeviceFormValues.env,
          serialNumber: createDeviceFormValues.deviceSerial as string,
          spaceId: createDeviceFormValues.spaceId,
          displayName: createDeviceFormValues.displayName,
        },
      });
    },
    [appId, organisationId, phyhubDeviceCreate],
  );

  return (
    <SetupPhyhubDevice
      {...props}
      user={user}
      fetchEnvironments={fetchEnvironments}
      fetchSpaces={fetchSpaces}
      createDevice={handleCreateDevice}
      environments={environments}
      spaces={spaces}
      app={app}
    />
  );
};

export default SetupPhyhubDeviceContainer;
