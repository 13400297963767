import React, { FC } from 'react';
import styled from '@emotion/styled';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import PhyhubDevicesInstallationItem, {
  PhyhubDevicesInstallationItemSize,
} from '../../../phyhub-devices-installation-item/phyhub-devices-installation-item.component';

interface PhyhubDevicesTableInstallationCellProps {
  tenantId: string;
  deviceInstallations: OrganisationApp[];
}

const PhyhubDevicesTableInstallationCell: FC<PhyhubDevicesTableInstallationCellProps> = (
  props,
) => {
  const { tenantId, deviceInstallations } = props;

  return (
    <Wrapper>
      {deviceInstallations.map((installation) => (
        <PhyhubDevicesInstallationItem
          key={installation.id}
          size={PhyhubDevicesInstallationItemSize.SMALL}
          tenantId={tenantId}
          installation={installation}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export default PhyhubDevicesTableInstallationCell;
