import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import { useModalsManager } from '../../../modals-manager-provider/modals-manager.provider';
import PhyhubDevicesRestartModal from '../../phyhub-devices-modals/phyhub-devices-restart-modal/phyhub-devices-restart-modal.component';

const MODAL_KEY = 'phyhubDevicesRestart';

interface UsePhyhubDevicesRestartFlowParams {
  tenantId: string;
  onMassActionSuccess: () => void;
}

interface UsePhyhubDevicesRestartFlowReturn {
  handleRestart: (selectedDevices: PhyhubDevice[]) => void;
}

const usePhyhubDevicesRestartFlow = (
  params: UsePhyhubDevicesRestartFlowParams,
): UsePhyhubDevicesRestartFlowReturn => {
  const { tenantId, onMassActionSuccess } = params;

  const [devicesForRestart, setDevicesForRestart] = useState<PhyhubDevice[]>([]);

  const handleRestart = useCallback((selectedDevices: PhyhubDevice[]) => {
    setDevicesForRestart(selectedDevices);
  }, []);

  const handleRestartConfirm = useCallback(
    (deviceIds: string[]) => {
      setDevicesForRestart([]);

      // TODO: Implement device restarting logic when API is ready
      console.log('Restart Confirm', { tenantId, selectedDeviceIds: deviceIds });

      onMassActionSuccess();
    },
    [tenantId, onMassActionSuccess],
  );

  const handleRestartReset = useCallback(() => {
    setDevicesForRestart([]);
  }, []);

  const modalNode = useMemo(
    () => (
      <PhyhubDevicesRestartModal
        isVisible={!!devicesForRestart.length}
        devices={devicesForRestart}
        onCancel={handleRestartReset}
        onRestartConfirm={handleRestartConfirm}
      />
    ),
    [devicesForRestart, handleRestartConfirm, handleRestartReset],
  );

  const { addModal, removeModal } = useModalsManager();

  useEffect(() => {
    addModal({
      key: MODAL_KEY,
      node: modalNode,
    });

    return () => {
      removeModal(MODAL_KEY);
    };
  }, [addModal, modalNode, removeModal]);

  return {
    handleRestart,
  };
};

export default usePhyhubDevicesRestartFlow;
