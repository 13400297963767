import { Alert } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

interface PhyhubDevicesWelcomeAlertProps {
  legacyDevicesPath: string;
}

const PhyhubDevicesWelcomeAlert = ({
  legacyDevicesPath,
}: PhyhubDevicesWelcomeAlertProps) => {
  return (
    <Alert
      message="Welcome to Phyhub!"
      description={
        <p>
          Phyhub is your new and improved way to manage devices on Phygrid.
          <br />
          Looking for your existing legacy devices? Find them{' '}
          <Link to={legacyDevicesPath}>here</Link>.
          {/*          <br />
          Curious about migrating to Phyhub? Learn more <a href="#">here</a>.*/}
        </p>
      }
      type="info"
      showIcon
    />
  );
};

export default PhyhubDevicesWelcomeAlert;
