import { useQuery } from 'react-query';
import { useGridDataService } from '../grid-data-service-provider';
import { DataResidencyEnum } from '../../../store/types/organisation';
import routes from '../use-analytics-report/routes';
import { AnalyticsSchemaAwareReport } from './types';

const useTenantAnalyticsReport = (
  tenantId: string,
  dataResidency: DataResidencyEnum,
  dateFrom: string,
  dateTo: string,
) => {
  const gridDataService = useGridDataService();

  return useQuery(
    ['tenantId', tenantId, 'dateFrom', dateFrom, 'dateTo', dateTo],
    () =>
      gridDataService.get<AnalyticsSchemaAwareReport[]>(
        routes.getTenantAnalyticsSchemaAwareReport(tenantId),
        dataResidency,
        {
          dateFrom,
          dateTo,
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default useTenantAnalyticsReport;
