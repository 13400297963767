import { useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';
import phyhubDevicesQueryKeys from './phyhub-devices-query-keys';
import { usePhyhubService } from '../../../app/common/phyhub-service-provider/phyhub-service.provider';
import { PhyhubDevicesDisconnectBody } from '../../../services/phyhub/types/phyhub-devices-disconnect-body.interface';

export interface PhyhubDevicesDisconnectMutationParams {
  tenantId: string;
  payload: PhyhubDevicesDisconnectBody;
}

const usePhyhubDevicesDisconnect = () => {
  const phyhubService = usePhyhubService();
  const queryClient = useQueryClient();

  return useMutation(
    ({ tenantId, payload }: PhyhubDevicesDisconnectMutationParams) =>
      phyhubService.disconnectDevices({ tenantId }, payload),
    {
      onSuccess: (_, params) =>
        queryClient.invalidateQueries(
          phyhubDevicesQueryKeys.manyPrefixKeys(params.tenantId),
        ),
      onError: (error) => {
        Sentry.captureException(error);
      },
    },
  );
};

export default usePhyhubDevicesDisconnect;
