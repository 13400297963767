import { useQuery } from 'react-query';
import phyhubDevicesQueryKeys from './phyhub-devices-query-keys';
import { usePhyhubService } from '../../../app/common/phyhub-service-provider/phyhub-service.provider';
import { PhyhubDeviceListParamsFilters } from '../../../services/phyhub/types/phyhub-device-list-params.interface';

export interface PhyhubDevicesOverviewQueryParams {
  tenantId: string;
  search?: string;
  filters?: PhyhubDeviceListParamsFilters;
}

const usePhyhubDevicesOverview = (
  phyhubDevicesOverviewQueryParams: PhyhubDevicesOverviewQueryParams,
) => {
  const phyhubService = usePhyhubService();

  return useQuery(
    phyhubDevicesQueryKeys.getPhyhubDevicesOverviewKeys(phyhubDevicesOverviewQueryParams),
    () =>
      phyhubService.getDevicesOverview({
        tenantId: phyhubDevicesOverviewQueryParams.tenantId,
        search: phyhubDevicesOverviewQueryParams.search,
        filters: phyhubDevicesOverviewQueryParams.filters,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default usePhyhubDevicesOverview;
