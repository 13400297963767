import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { PhyhubDeviceStatusesBreakdown } from '../../../../services/phyhub/types/phyhub-device-statuses-breakdown.interface';
import { PhyhubDeviceStatusColorEnum } from '../phyhub-device-status/phyhub-device-status';
import { PhyhubDeviceStatusEnum } from '../../../../services/phyhub/enums/phyhub-device-status.enum';

export enum ProgressSize {
  SMALL,
  DEFAULT,
}

interface PhyhubDeviceStatusesBarProps {
  size: ProgressSize;
  statusesBreakdown: PhyhubDeviceStatusesBreakdown;
}

const PhyhubDeviceStatusesBar: FC<PhyhubDeviceStatusesBarProps> = (props) => {
  const { size, statusesBreakdown } = props;

  const onlinePercentage = useMemo(() => {
    const onlineStatusRecord = statusesBreakdown.statuses.find(({ type }) =>
      [PhyhubDeviceStatusEnum.ONLINE, PhyhubDeviceStatusEnum.OK].includes(type),
    );

    return onlineStatusRecord
      ? (onlineStatusRecord.count / statusesBreakdown.total) * 100
      : 0;
  }, [statusesBreakdown]);

  const failingPercentage = useMemo(() => {
    const failingStatusRecord = statusesBreakdown.statuses.find(
      ({ type }) => type === PhyhubDeviceStatusEnum.FAILING,
    );

    return failingStatusRecord
      ? (failingStatusRecord.count / statusesBreakdown.total) * 100
      : 0;
  }, [statusesBreakdown]);

  const offlinePercentage = useMemo(() => {
    const offlineStatusRecord = statusesBreakdown.statuses.find(
      ({ type }) => PhyhubDeviceStatusEnum.OFFLINE === type,
    );

    return offlineStatusRecord
      ? (offlineStatusRecord.count / statusesBreakdown.total) * 100
      : 0;
  }, [statusesBreakdown]);

  return (
    <StatusBar size={size}>
      {!!onlinePercentage && (
        <StatusBarInner
          color={PhyhubDeviceStatusColorEnum.OK}
          widthPercent={onlinePercentage}
        />
      )}
      {!!failingPercentage && (
        <StatusBarInner
          color={PhyhubDeviceStatusColorEnum.WARNING}
          widthPercent={failingPercentage}
        />
      )}
      {!!offlinePercentage && (
        <StatusBarInner
          color={PhyhubDeviceStatusColorEnum.ERROR}
          widthPercent={offlinePercentage}
        />
      )}
    </StatusBar>
  );
};

const StatusBarInner = styled.div<{
  color: PhyhubDeviceStatusColorEnum;
  widthPercent: number;
}>`
  width: ${({ widthPercent }) => `${widthPercent}%`};
  height: 100%;
  background-color: ${({ color }) => color};
  transition: width 0.3s;
`;

const StatusBar = styled.div<{ size: ProgressSize }>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 10px;
  width: 100%;
  gap: ${({ size }) => (size === ProgressSize.SMALL ? '2px' : '5px')};

  ${StatusBarInner} {
    border-radius: ${({ size }) => (size === ProgressSize.SMALL ? '1px' : '4px')};
  }
`;

export default PhyhubDeviceStatusesBar;
