import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import PhyhubDeviceStatusesBar, {
  ProgressSize,
} from '../../../phyhub-device-statuses-bar/phyhub-device-statuses-bar.component';
import PhyhubDeviceStatus from '../../../phyhub-device-status/phyhub-device-status';
import { GridBase, TableCell } from '../phyhub-devices-overview-common.components';
import { PhyhubDeviceTypeStatusBreakdown } from '../../../../../../services/phyhub/types/phyhub-device-type-status-breakdown.interface';
import {
  PHYHUB_DEVICE_STATUS_TRANSLATION_MAP,
  PHYHUB_DEVICE_TYPE_TRANSLATION_MAP,
  PHYHUB_DEVICES_OVERVIEW_STATUSES,
} from '../../../../../../store/types/phyhub-devices';

interface PhyhubDevicesOverviewTableContentProps {
  typeStatusBreakdowns: PhyhubDeviceTypeStatusBreakdown[];
}

const PhyhubDevicesOverviewTableContent: FC<PhyhubDevicesOverviewTableContentProps> = (
  props,
) => {
  const { typeStatusBreakdowns } = props;

  const { t } = useTranslation();

  return (
    <TableContent>
      {typeStatusBreakdowns.map((deviceTypeBreakdown) => {
        const { type: deviceType, ...statusesBreakdown } = deviceTypeBreakdown;

        return (
          <TableRow key={`overview_table_row_${deviceType}`}>
            <TableCell>
              {statusesBreakdown.total}{' '}
              {t(PHYHUB_DEVICE_TYPE_TRANSLATION_MAP[deviceType])}
            </TableCell>
            <TableCell align="right">
              <PhyhubDeviceStatusesBar
                size={ProgressSize.SMALL}
                statusesBreakdown={statusesBreakdown}
              />
            </TableCell>
            {PHYHUB_DEVICES_OVERVIEW_STATUSES.map((status) => {
              const statusCount = statusesBreakdown.statuses.find(
                ({ type }) => type === status,
              );

              return (
                <TableCell key={`overview_table_cell_${deviceType}_${status}`}>
                  <PhyhubDeviceStatus status={status}>
                    {statusCount ? statusCount.count : 0}{' '}
                    {t(PHYHUB_DEVICE_STATUS_TRANSLATION_MAP[status])}
                  </PhyhubDeviceStatus>
                </TableCell>
              );
            })}
            <TableCell />
          </TableRow>
        );
      })}
    </TableContent>
  );
};

const TableContent = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;

const TableRow = styled(GridBase)`
  font-size: 14px;
  line-height: 22px;

  &:nth-child(n + 2) {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

export default memo(PhyhubDevicesOverviewTableContent);
