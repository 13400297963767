import { useCallback, useMemo, useState } from 'react';
import {
  PhyhubDeviceListParamsFilters,
  PhyhubDeviceListParamsFiltersOperatorEnum,
} from '../../../../services/phyhub/types/phyhub-device-list-params.interface';
import { ActiveFilter } from '../../../../store/types/filters';

interface UsePhyhubDevicesSearchAndFiltersParams {
  presetFilters?: PhyhubDeviceListParamsFilters;
}

interface UsePhyhubDevicesSearchAndFiltersReturn {
  searchPhrase?: string;
  handleSetSearchPhrase: (searchPhrase: string) => void;
  filters: PhyhubDeviceListParamsFilters;
  handleFiltersChange: (filters: ActiveFilter[]) => void;
}

export const usePhyhubDevicesSearchAndFilters = (
  params: UsePhyhubDevicesSearchAndFiltersParams = {},
): UsePhyhubDevicesSearchAndFiltersReturn => {
  const { presetFilters } = params;

  const [searchPhrase, setSearchPhrase] = useState<string>();

  const handleSetSearchPhrase = useCallback((searchPhrase: string) => {
    setSearchPhrase(searchPhrase);
  }, []);

  const [selectedFilters, setSelectedFilters] = useState<PhyhubDeviceListParamsFilters>(
    {},
  );

  const filters = useMemo<PhyhubDeviceListParamsFilters>(
    () => ({
      ...presetFilters,
      ...selectedFilters,
    }),
    [presetFilters, selectedFilters],
  );

  const handleFiltersChange = useCallback((filters: ActiveFilter[]) => {
    const nextSelectedFilters: PhyhubDeviceListParamsFilters = filters.reduce<
      PhyhubDeviceListParamsFilters
    >((res, curr) => {
      if (!curr.valueId) {
        return res;
      }

      const existingFilter = res[curr.typeId];

      return {
        ...res,
        [curr.typeId]: {
          op: PhyhubDeviceListParamsFiltersOperatorEnum.IN,
          val: [...(existingFilter ? existingFilter.val : []), curr.valueId.toString()],
        },
      };
    }, {});

    setSelectedFilters(nextSelectedFilters);
  }, []);

  return {
    searchPhrase,
    handleSetSearchPhrase,
    filters,
    handleFiltersChange,
  };
};

export default usePhyhubDevicesSearchAndFilters;
