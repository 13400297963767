import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Row, Col, Form, Tooltip, Icon } from 'antd';
import Environment from '../../../../../../store/types/environment';

interface PhyhubDevicesUpdateEnvironmentControlsProps {
  targetEnvironmentName: string;
  availableEnvironments: Environment[];
  onTargetEnvironmentChange: (value: string) => void;
}

const PhyhubDevicesUpdateEnvironmentControls: FC<PhyhubDevicesUpdateEnvironmentControlsProps> = (
  props,
) => {
  const {
    targetEnvironmentName,
    availableEnvironments,
    onTargetEnvironmentChange,
  } = props;

  const { t } = useTranslation();

  const handleTargetEnvironmentChange = useCallback(
    (value: string) => {
      onTargetEnvironmentChange(value);
    },
    [onTargetEnvironmentChange],
  );

  return (
    <Form layout="vertical">
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <Form.Item
            label={
              <span>
                {t('phyhubDevices.label.desiredEnvironment')}{' '}
                <Tooltip title={t('phyhubDevices.tooltip.desiredEnvironment')}>
                  <Icon type="info-circle" />
                </Tooltip>
              </span>
            }
          >
            <Select
              value={targetEnvironmentName}
              onChange={handleTargetEnvironmentChange}
            >
              {availableEnvironments.map((environment) => (
                <Select.Option
                  key={environment.environmentName}
                  value={environment.environmentName}
                >
                  {environment.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PhyhubDevicesUpdateEnvironmentControls;
