import { useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';
import phyhubDevicesQueryKeys from './phyhub-devices-query-keys';
import { usePhyhubService } from '../../../app/common/phyhub-service-provider/phyhub-service.provider';
import { PhyhubDeviceCreateBody } from '../../../services/phyhub/types/phyhub-device-create-body.interface';

export interface PhyhubDevicesUpdateMutationParams {
  tenantId: string;
  payload: PhyhubDeviceCreateBody;
}

const usePhyhubDevicesCreate = () => {
  const phyhubService = usePhyhubService();
  const queryClient = useQueryClient();

  return useMutation(
    ({ tenantId, payload }: PhyhubDevicesUpdateMutationParams) =>
      phyhubService.createDevice(tenantId, payload),
    {
      onSuccess: (_, { tenantId }) =>
        queryClient.invalidateQueries(phyhubDevicesQueryKeys.manyPrefixKeys(tenantId)),
      onError: (error) => {
        Sentry.captureException(error);
      },
    },
  );
};

export default usePhyhubDevicesCreate;
