import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FilterItem, { FilterOption } from '../../../../../../store/types/filters';
import OrganisationSpace from '../../../../../../store/types/organisation-space';
import {
  PHYHUB_DEVICE_ARCHITECTURE_NAMES_MAP,
  PHYHUB_DEVICE_STATUS_FILTERS,
  PHYHUB_DEVICE_STATUS_TRANSLATION_MAP,
} from '../../../../../../store/types/phyhub-devices';
import Environment from '../../../../../../store/types/environment';
import OrganisationApp from '../../../../../../store/types/organisation-app';

export const PHYHUB_DEVICE_FILTERS_MAP = {
  DEVICE_ARCHITECTURE: 'architectures',
  ENVIRONMENT: 'environments',
  INSTALLATION_ID: 'installationIds',
  SPACE_ID: 'spaceIds',
  STATUS: 'statuses',
};

interface UsePhyhubDeviceFiltersParams {
  environments?: Environment[];
  installations?: OrganisationApp[];
  spaces?: OrganisationSpace[];
}

interface UsePhyhubDeviceFiltersReturn {
  filterOptions: FilterItem[];
}

export default function usePhyhubDeviceFilters(
  params: UsePhyhubDeviceFiltersParams,
): UsePhyhubDeviceFiltersReturn {
  const { environments = [], installations = [], spaces = [] } = params;

  const { t } = useTranslation();

  const architectureOptions = useMemo<FilterOption[]>(
    () =>
      Object.entries(PHYHUB_DEVICE_ARCHITECTURE_NAMES_MAP).map(
        ([key, translationKey]) => ({
          id: key,
          label: t(translationKey),
        }),
      ),
    [t],
  );

  const environmentOptions = useMemo<FilterOption[]>(
    () =>
      environments.map((environment) => ({
        id: environment.environmentName,
        label: environment.displayName,
      })),
    [environments],
  );

  const installationOptions = useMemo<FilterOption[]>(
    () =>
      installations.map((installation) => ({
        id: installation.id,
        label: installation.displayName,
      })),
    [installations],
  );

  const spaceOptions = useMemo<FilterOption[]>(
    () =>
      spaces.map((space) => ({
        id: space.id,
        label: space.displayName,
      })),
    [spaces],
  );

  const statusOptions = useMemo<FilterOption[]>(
    () =>
      PHYHUB_DEVICE_STATUS_FILTERS.map((status) => ({
        id: status,
        label: t(PHYHUB_DEVICE_STATUS_TRANSLATION_MAP[status]),
      })),
    [t],
  );

  const filterOptions = useMemo<FilterItem[]>(() => {
    const items: FilterItem[] = [];

    items.push({
      id: PHYHUB_DEVICE_FILTERS_MAP.DEVICE_ARCHITECTURE,
      label: t('filterDevice.architecture'),
      options: architectureOptions,
    });

    items.push({
      id: PHYHUB_DEVICE_FILTERS_MAP.STATUS,
      label: t('filterDevice.status'),
      options: statusOptions,
    });

    if (environmentOptions.length) {
      items.push({
        id: PHYHUB_DEVICE_FILTERS_MAP.ENVIRONMENT,
        label: t('filterDevice.environment'),
        options: environmentOptions,
      });
    }

    if (installationOptions.length) {
      items.push({
        id: PHYHUB_DEVICE_FILTERS_MAP.INSTALLATION_ID,
        label: t('filterDevice.installation'),
        options: installationOptions,
      });
    }

    if (spaceOptions.length) {
      items.push({
        id: PHYHUB_DEVICE_FILTERS_MAP.SPACE_ID,
        label: t('filterDevice.space'),
        options: spaceOptions,
      });
    }

    return items;
  }, [
    architectureOptions,
    environmentOptions,
    installationOptions,
    spaceOptions,
    statusOptions,
    t,
  ]);

  return { filterOptions };
}
