import React, { FC, ReactNode, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { ColumnProps, TableRowSelection } from 'antd/lib/table';
import { SortCriteria, SortOrder } from '../../../store/types/sort';
import FilterItem, { ActiveFilter } from '../../../store/types/filters';
import PhyhubDevicesTableContainer from './phyhub-devices-list/phyhub-devices-table/phyhub-devices-table.container';
import { BlockWrapper } from './phyhub-devices-common.components';
import PhyhubDevicesSearchAndSort from './phyhub-devices-list/phyhub-devices-search-and-sort/phyhub-devices-search-and-sort.component';
import {
  PhyhubDeviceListParamsFilters,
  PhyhubDeviceListParamsSort,
} from '../../../services/phyhub/types/phyhub-device-list-params.interface';
import { PhyhubDevice } from '../../../services/phyhub/types/phyhub-device.interface';

interface PhyhubDevicesContainerProps {
  isTableLoading?: boolean;
  searchPhrase?: string;
  tenantId: string;
  filterOptions: FilterItem[];
  filters?: PhyhubDeviceListParamsFilters;
  getTableRowSelection?: (
    deviceItems: PhyhubDevice[],
  ) => TableRowSelection<PhyhubDevice> | undefined;
  header?: ReactNode;
  overview?: ReactNode;
  sortCriteriaOptions: SortCriteria[];
  tableColumns: ColumnProps<PhyhubDevice>[];
  onFiltersChange: (filters: ActiveFilter[]) => void;
  onSearch: (searchPhrase: string) => void;
}

const PhyhubDevicesContainer: FC<PhyhubDevicesContainerProps> = (props) => {
  const {
    isTableLoading = false,
    searchPhrase,
    tenantId,
    filterOptions,
    filters,
    getTableRowSelection,
    header,
    overview,
    sortCriteriaOptions,
    tableColumns,
  } = props;

  const [sort, setSort] = useState<PhyhubDeviceListParamsSort>();

  const handleCriteriaChange = useCallback((criteria: SortCriteria, order: SortOrder) => {
    setSort({ [criteria.id.toString()]: order.id });
  }, []);

  return (
    <>
      {!!header && header}
      <SearchAndSortWrapper>
        <PhyhubDevicesSearchAndSort
          filterOptions={filterOptions}
          sortCriteriaOptions={sortCriteriaOptions}
          onCriteriaChange={handleCriteriaChange}
          onFiltersChange={props.onFiltersChange}
          onSearch={props.onSearch}
        />
      </SearchAndSortWrapper>
      {!!overview && <BlockWrapper>{overview}</BlockWrapper>}
      <BlockWrapper>
        <PhyhubDevicesTableContainer
          isLoading={isTableLoading}
          searchPhrase={searchPhrase}
          tenantId={tenantId}
          columns={tableColumns}
          filters={filters}
          sort={sort}
          getTableRowSelection={getTableRowSelection}
        />
      </BlockWrapper>
    </>
  );
};

const SearchAndSortWrapper = styled.div`
  position: relative;
  margin: 20px 0;
`;

export default PhyhubDevicesContainer;
