import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import OrganisationApp from '../../../../store/types/organisation-app';
import getInstallationAppIcon from '../../../../utils/installations/get-installation-app-icon-url';

export enum PhyhubDevicesInstallationItemSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

interface PhyhubInstallationItemProps {
  size?: PhyhubDevicesInstallationItemSize;
  tenantId: string;
  installation: OrganisationApp;
}

const PhyhubDevicesInstallationItem: FC<PhyhubInstallationItemProps> = (props) => {
  const {
    size = PhyhubDevicesInstallationItemSize.MEDIUM,
    tenantId,
    installation,
  } = props;

  return (
    <Link to={`/organisations/${tenantId}/apps/installations/${installation.id}`}>
      <StyledImage alt="" size={size} src={getInstallationAppIcon(installation)} />
      {installation.displayName}
    </Link>
  );
};

const IMAGE_SIZES_MAP: Record<PhyhubDevicesInstallationItemSize, string> = {
  [PhyhubDevicesInstallationItemSize.SMALL]: '20px',
  [PhyhubDevicesInstallationItemSize.MEDIUM]: '32px',
};

const StyledImage = styled.img<{ size: PhyhubDevicesInstallationItemSize }>`
  margin: 0 4px 0 0;
  width: ${({ size }) => IMAGE_SIZES_MAP[size]};
  height: ${({ size }) => IMAGE_SIZES_MAP[size]};
`;

export default PhyhubDevicesInstallationItem;
