import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Option,
  Select,
  validateRequired,
} from '../../../../../../common/react-final-form';
import { FieldContainer } from '../../../../../../common/phyhub-devices/phyhub-devices-common.components';
import {
  PHYHUB_DEVICE_SCREEN_ORIENTATION_TRANSLATION_MAP,
  PHYHUB_DEVICE_SCREEN_ORIENTATIONS,
  PHYHUB_DEVICE_SCREEN_RESOLUTIONS,
  PHYHUB_DEVICE_SCREEN_SCALES,
  PHYHUB_DEVICE_SCREEN_SOUND_INPUT_TRANSLATION_MAP,
  PHYHUB_DEVICE_SCREEN_SOUND_INPUTS,
  PHYHUB_DEVICE_SCREEN_SOUND_OUTPUT_TRANSLATION_MAP,
  PHYHUB_DEVICE_SCREEN_SOUND_OUTPUTS,
} from '../../../../../../../store/types/phyhub-devices';

const PhyhubDeviceDetailsScreenSettingsFormFields = () => {
  const { t } = useTranslation();

  const screenResolutionOptions = useMemo<Option[]>(
    () =>
      Object.values(PHYHUB_DEVICE_SCREEN_RESOLUTIONS).map((resolution) => ({
        label: resolution,
        value: resolution,
      })),
    [],
  );

  const scaleOptions = useMemo<Option[]>(
    () =>
      Object.values(PHYHUB_DEVICE_SCREEN_SCALES).map((scale) => ({
        label: scale,
        value: scale,
      })),
    [],
  );

  const screenOrientationOptions = useMemo<Option[]>(
    () =>
      PHYHUB_DEVICE_SCREEN_ORIENTATIONS.map((screenOrientation) => ({
        label: t(PHYHUB_DEVICE_SCREEN_ORIENTATION_TRANSLATION_MAP[screenOrientation]),
        value: screenOrientation,
      })),
    [t],
  );

  const soundOutputOptions = useMemo<Option[]>(
    () =>
      PHYHUB_DEVICE_SCREEN_SOUND_OUTPUTS.map((soundOutput) => ({
        label: t(PHYHUB_DEVICE_SCREEN_SOUND_OUTPUT_TRANSLATION_MAP[soundOutput]),
        value: soundOutput,
      })),
    [t],
  );

  const soundInputOptions = useMemo<Option[]>(
    () =>
      PHYHUB_DEVICE_SCREEN_SOUND_INPUTS.map((soundInput) => ({
        label: t(PHYHUB_DEVICE_SCREEN_SOUND_INPUT_TRANSLATION_MAP[soundInput]),
        value: soundInput,
      })),
    [t],
  );

  return (
    <>
      <FieldContainer>
        <Select
          isRequired
          isSearchEnabled
          label={t('phyhubDevices.label.screenResolution')}
          name="screen.screenResolution"
          options={screenResolutionOptions}
          validators={[validateRequired()]}
        />
      </FieldContainer>
      <FieldContainer>
        <Select
          isRequired
          isSearchEnabled
          label={t('phyhubDevices.label.scale')}
          name="screen.scale"
          options={scaleOptions}
          validators={[validateRequired()]}
        />
      </FieldContainer>
      <FieldContainer>
        <Select
          isRequired
          isSearchEnabled
          label={t('phyhubDevices.label.screenOrientation')}
          name="screen.screenOrientation"
          options={screenOrientationOptions}
          validators={[validateRequired()]}
        />
      </FieldContainer>
      <FieldContainer>
        <Select
          isRequired
          isSearchEnabled
          label={t('phyhubDevices.label.soundOutput')}
          name="screen.soundOutput"
          options={soundOutputOptions}
          validators={[validateRequired()]}
        />
      </FieldContainer>
      <FieldContainer>
        <Select
          isRequired
          isSearchEnabled
          label={t('phyhubDevices.label.soundInput')}
          name="screen.soundInput"
          options={soundInputOptions}
          validators={[validateRequired()]}
        />
      </FieldContainer>
    </>
  );
};

export default PhyhubDeviceDetailsScreenSettingsFormFields;
