import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { ColumnProps, TableRowSelection } from 'antd/lib/table';
import PhyhubDevicesTable from './phyhub-devices-table.component';
import usePagination from '../../../pagination/hook/use-pagination';
import usePaginationQueryParams from '../../../use-pagination-query-params';
import usePhyhubDevicesList, {
  PhyhubDevicesListQueryParams,
} from '../../../../../store/hooks/phyhub-devices/use-phyhub-devices-list';
import { StateWrapper } from '../../phyhub-devices-common.components';
import Overlay from '../../../overlay';
import { Spinner } from '../../../spinner';
import ErrorView from '../../../error-view/error-view.component';
import {
  PhyhubDeviceListParamsFilters,
  PhyhubDeviceListParamsSort,
} from '../../../../../services/phyhub/types/phyhub-device-list-params.interface';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';

interface PhyhubDevicesTableContainerProps {
  isLoading: boolean;
  searchPhrase?: string;
  tenantId: string;
  columns: ColumnProps<PhyhubDevice>[];
  filters?: PhyhubDeviceListParamsFilters;
  getTableRowSelection?: (
    deviceItems: PhyhubDevice[],
  ) => TableRowSelection<PhyhubDevice> | undefined;
  sort?: PhyhubDeviceListParamsSort;
}

const PhyhubDevicesTableContainer: FC<PhyhubDevicesTableContainerProps> = (props) => {
  const {
    isLoading,
    tenantId,
    searchPhrase,
    columns,
    filters,
    getTableRowSelection,
    sort,
  } = props;

  const { t } = useTranslation();

  const { page, defaultPage, pageSize, defaultPageSize } = usePagination();

  const [paginationQueryParams] = usePaginationQueryParams({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
  });

  const phyhubDevicesListQueryParams = useMemo<PhyhubDevicesListQueryParams>(
    () => ({
      tenantId,
      filters,
      sort,
      limit: paginationQueryParams.limit || defaultPageSize,
      page: paginationQueryParams.page || defaultPage,
      search: searchPhrase,
    }),
    [
      tenantId,
      filters,
      sort,
      paginationQueryParams,
      searchPhrase,
      defaultPageSize,
      defaultPage,
    ],
  );

  const {
    isError: isPhyhubDevicesListError,
    isLoading: isPhyhubDevicesListLoading,
    isFetching: isPhyhubDevicesListFetching,
    isSuccess: isPhyhubDevicesListIsSuccess,
    data: phyhubDevicesCollection,
  } = usePhyhubDevicesList(phyhubDevicesListQueryParams);

  const rowSelection: TableRowSelection<PhyhubDevice> | undefined = useMemo(() => {
    if (getTableRowSelection && phyhubDevicesCollection) {
      return getTableRowSelection(phyhubDevicesCollection.docs);
    }
  }, [getTableRowSelection, phyhubDevicesCollection]);

  if (isPhyhubDevicesListLoading) {
    return (
      <StateWrapper>
        <Overlay>
          <Spinner />
        </Overlay>
      </StateWrapper>
    );
  }

  if (isPhyhubDevicesListError) {
    return (
      <StateWrapper>
        <ErrorView
          title={t('phyhubDevices.list.error.title')}
          content={t('phyhubDevices.list.error.subtitle')}
        />
      </StateWrapper>
    );
  }

  if (!isPhyhubDevicesListIsSuccess || !phyhubDevicesCollection) {
    return (
      <StateWrapper>
        <Empty description={t('phyhubDevices.list.emptyState')} />
      </StateWrapper>
    );
  }

  return (
    <PhyhubDevicesTable
      isFetching={isPhyhubDevicesListFetching || isLoading}
      columns={columns}
      paginationCollection={phyhubDevicesCollection}
      rowSelection={rowSelection}
    />
  );
};

export default PhyhubDevicesTableContainer;
