import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import { useModalsManager } from '../../../modals-manager-provider/modals-manager.provider';
import PhyhubDevicesRebootModal from '../../phyhub-devices-modals/phyhub-devices-reboot-modal/phyhub-devices-restart-modal.component';

const MODAL_KEY = 'phyhubDevicesReboot';

interface UsePhyhubDevicesRebootFlowParams {
  tenantId: string;
  onMassActionSuccess: () => void;
}

interface UsePhyhubDevicesRebootFlowReturn {
  handleReboot: (selectedDevices: PhyhubDevice[]) => void;
}

const usePhyhubDevicesRebootFlow = (
  params: UsePhyhubDevicesRebootFlowParams,
): UsePhyhubDevicesRebootFlowReturn => {
  const { tenantId, onMassActionSuccess } = params;

  const [devicesForReboot, setDevicesForReboot] = useState<PhyhubDevice[]>([]);

  const handleReboot = useCallback((selectedDevices: PhyhubDevice[]) => {
    setDevicesForReboot(selectedDevices);
  }, []);

  const handleRebootConfirm = useCallback(
    (deviceIds: string[]) => {
      setDevicesForReboot([]);

      // TODO: Implement device rebooting logic when API is ready
      console.log('Reboot Confirm', { tenantId, selectedDeviceIds: deviceIds });

      onMassActionSuccess();
    },
    [tenantId, onMassActionSuccess],
  );

  const handleRebootReset = useCallback(() => {
    setDevicesForReboot([]);
  }, []);

  const modalNode = useMemo(
    () => (
      <PhyhubDevicesRebootModal
        isVisible={!!devicesForReboot.length}
        devices={devicesForReboot}
        onCancel={handleRebootReset}
        onRebootConfirm={handleRebootConfirm}
      />
    ),
    [devicesForReboot, handleRebootConfirm, handleRebootReset],
  );

  const { addModal, removeModal } = useModalsManager();

  useEffect(() => {
    addModal({
      key: MODAL_KEY,
      node: modalNode,
    });

    return () => {
      removeModal(MODAL_KEY);
    };
  }, [addModal, modalNode, removeModal]);

  return {
    handleReboot,
  };
};

export default usePhyhubDevicesRebootFlow;
