import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import PhyhubDevicesOverview from './phyhub-devices-overview.component';
import usePhyhubDevicesOverview from '../../../../../store/hooks/phyhub-devices/use-phyhub-devices-overview';
import { StateWrapper } from '../../phyhub-devices-common.components';
import Overlay from '../../../overlay';
import { Spinner } from '../../../spinner';
import ErrorView from '../../../error-view/error-view.component';
import { PhyhubDeviceListParamsFilters } from '../../../../../services/phyhub/types/phyhub-device-list-params.interface';

interface PhyhubDevicesOverviewContainerProps {
  searchPhrase?: string;
  tenantId: string;
  filters?: PhyhubDeviceListParamsFilters;
}

const PhyhubDevicesOverviewContainer: FC<PhyhubDevicesOverviewContainerProps> = (
  props,
) => {
  const { searchPhrase, tenantId, filters } = props;

  const { t } = useTranslation();

  const {
    isError: isPhyhubDevicesOverviewError,
    isFetching: isPhyhubDevicesOverviewFetching,
    isLoading: isPhyhubDevicesOverviewLoading,
    isSuccess: isPhyhubDevicesOverviewSuccess,
    data: phyhubDeviceOverviewReport,
  } = usePhyhubDevicesOverview({ tenantId, filters, search: searchPhrase });

  if (isPhyhubDevicesOverviewLoading || isPhyhubDevicesOverviewFetching) {
    return (
      <StateWrapper>
        <Overlay>
          <Spinner />
        </Overlay>
      </StateWrapper>
    );
  }

  if (isPhyhubDevicesOverviewError) {
    return (
      <StateWrapper>
        <ErrorView />
      </StateWrapper>
    );
  }

  if (!isPhyhubDevicesOverviewSuccess || !phyhubDeviceOverviewReport) {
    return (
      <StateWrapper>
        <Empty description={t('phyhubDevices.list.emptyState')} />
      </StateWrapper>
    );
  }

  return <PhyhubDevicesOverview report={phyhubDeviceOverviewReport} />;
};

export default PhyhubDevicesOverviewContainer;
