import React, { useMemo, useState } from 'react';
import { groupBy, uniq } from 'lodash';
import keys from 'lodash/keys';
import {
  useTenantEventsByDay,
  useInstallationEventsByDay,
  useSpaceEventsByDay,
  useDeviceEventsByDay,
  useAppEventsByDay,
} from '../../use-analytics-report';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import CardContainer from './card-container';
import PieChart from '../charts/pie';
import {
  DeviceEventsByDay,
  InstallationEventsByDay,
  SpaceEventsByDay,
  TenantEventsByDay,
  AppEventsByDay,
} from '../../use-analytics-report/aggregated-reports/types';
import { EventsListCard, GridStyles, InteractionType } from '@ombori/grid-reports';
import { useTranslation } from 'react-i18next';
import VerticalBar from '../charts/vertical-bar';

const buildDataItem = (
  date: string,
  data: { label: string; value: number }[],
): [string, { label: string; value: number }[]] => [date, data];

const buildData = (
  rawData: (
    | TenantEventsByDay
    | InstallationEventsByDay
    | SpaceEventsByDay
    | DeviceEventsByDay
    | AppEventsByDay
  )[],
) => {
  const groupedEvents = groupBy(rawData, (dataItem) => dataItem.date);

  const result = keys(groupedEvents).map((date) => {
    const list = groupedEvents[date].map((dataItem) => ({
      label: dataItem.eventType,
      value: dataItem.count,
    }));

    const result = buildDataItem(date, list);

    return result;
  });

  return result;
};

const transformToBarChartData = (
  data: [string, { label: string; value: number }[]][],
  maxBars = 10,
): { label: string; value: number }[] => {
  const flattened = data.flatMap(([_, events]) => events);
  const grouped = groupBy(flattened, 'label');
  const aggregated = Object.keys(grouped).map((label) => ({
    label,
    value: grouped[label].reduce((sum, event) => sum + event.value, 0),
  }));

  const sorted = aggregated.sort((a, b) => b.value - a.value);
  return sorted.slice(0, maxBars); // Keep only the top N items
};

interface EventsListProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  interactionType?: InteractionType;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

export const TenantEventsList: React.FC<EventsListProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();
  const title = t('eventsListCardTitle');

  const tenantEventsByDayState = useTenantEventsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
  });

  const data = useMemo(() => buildData(tenantEventsByDayState.data || []), [
    tenantEventsByDayState,
  ]);

  const barChartData = useMemo(() => transformToBarChartData(data), [data]);

  return (
    <CardContainer
      isLoading={tenantEventsByDayState.isLoading}
      isSuccess={tenantEventsByDayState.isSuccess}
      isError={tenantEventsByDayState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {tenantEventsByDayState.isSuccess && (
        <VerticalBar title={title} data={barChartData} />
      )}
    </CardContainer>
  );
};

interface InstallationEventsListProps
  extends EventsListProps,
    Omit<EventsListCard, 'type' | 'interactionType'> {
  installationId: string;
}

export const InstallationEventsList: React.FC<InstallationEventsListProps> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();
  const componentTitle: string = t('eventsListCardTitle');

  const installationEventsByDayState = useInstallationEventsByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
  });

  const data = useMemo(() => buildData(installationEventsByDayState.data || []), [
    installationEventsByDayState,
  ]);

  const barChartData = useMemo(() => transformToBarChartData(data), [data]);

  return (
    <CardContainer
      isLoading={installationEventsByDayState.isLoading}
      isSuccess={installationEventsByDayState.isSuccess}
      isError={installationEventsByDayState.isError}
      hasData={data.length > 0}
      title={componentTitle}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {installationEventsByDayState.isSuccess && (
        <VerticalBar title={componentTitle} data={barChartData} />
      )}
    </CardContainer>
  );
};

interface SpaceEventsListProps extends EventsListProps {
  spaceId: string;
}

export const SpaceEventsList: React.FC<SpaceEventsListProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();
  const title = t('eventsListCardTitle');

  const spaceEventsByDayState = useSpaceEventsByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
  });

  const data = useMemo(() => buildData(spaceEventsByDayState.data || []), [
    spaceEventsByDayState,
  ]);

  return (
    <CardContainer
      isLoading={spaceEventsByDayState.isLoading}
      isSuccess={spaceEventsByDayState.isSuccess}
      isError={spaceEventsByDayState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {spaceEventsByDayState.isSuccess && (
        <PieChart title={title} data={data} showTotal={false} />
      )}
    </CardContainer>
  );
};

interface DeviceEventsListProps extends EventsListProps {
  deviceId: string;
}

export const DeviceEventsList: React.FC<DeviceEventsListProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();
  const title = t('eventsListCardTitle');

  const deviceEventsByDayState = useDeviceEventsByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
  });

  const data = useMemo(() => buildData(deviceEventsByDayState.data || []), [
    deviceEventsByDayState,
  ]);

  const barChartData = useMemo(() => transformToBarChartData(data), [data]);

  return (
    <CardContainer
      isLoading={deviceEventsByDayState.isLoading}
      isSuccess={deviceEventsByDayState.isSuccess}
      isError={deviceEventsByDayState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {deviceEventsByDayState.isSuccess && (
        <VerticalBar title={title} data={barChartData} />
      )}
    </CardContainer>
  );
};

interface AppEventsListProps extends EventsListProps {
  appId: string;
}

export const AppEventsList: React.FC<AppEventsListProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  interactionType = InteractionType.All,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();
  const title = t('eventsListCardTitle');

  const appEventsByDayState = useAppEventsByDay({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
    interactionType,
  });

  const data = useMemo(() => buildData(appEventsByDayState.data || []), [
    appEventsByDayState,
  ]);

  const maxBars = 10; // Limit to top 10
  const barChartData = useMemo(() => {
    return transformToBarChartData(data, maxBars);
  }, [data]);

  return (
    <CardContainer
      isLoading={appEventsByDayState.isLoading}
      isSuccess={appEventsByDayState.isSuccess}
      isError={appEventsByDayState.isError}
      hasData={data.length > 0}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {appEventsByDayState.isSuccess && <VerticalBar title={title} data={barChartData} />}
    </CardContainer>
  );
};
