import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { message, Modal } from 'antd';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import usePhyhubDevicesRunCommand from '../../../../../store/hooks/phyhub-devices/use-phyhub-devices-run-command';
import { PhyhubDeviceCommandEnum } from '../../../../../services/phyhub/enums/phyhub-device-command.enum';

interface UsePhyhubDeviceRebootFlowParams {
  tenantId: string;
}

interface UsePhyhubDeviceRebootFlowReturn {
  isDeviceRebootLoading: boolean;
  handleDeviceReboot: (device: PhyhubDevice) => void;
}

const usePhyhubDeviceRebootFlow = (
  params: UsePhyhubDeviceRebootFlowParams,
): UsePhyhubDeviceRebootFlowReturn => {
  const { tenantId } = params;

  const { t } = useTranslation();

  const {
    isLoading: isPhyhubDevicesRebootLoading,
    mutateAsync: phyhubDevicesRunCommand,
  } = usePhyhubDevicesRunCommand();

  const handleDeviceRebootConfirm = useCallback(
    (deviceId: string) => async () => {
      try {
        await phyhubDevicesRunCommand({
          tenantId,
          payload: {
            command: PhyhubDeviceCommandEnum.Reboot,
            deviceIds: [deviceId],
          },
        });

        message.success(t('phyhubDevices.list.message.deviceRebootSuccess'));
      } catch {
        message.error(t('phyhubDevices.list.message.deviceRebootFailed'));
      }
    },
    [tenantId, phyhubDevicesRunCommand, t],
  );

  const handleDeviceReboot = useCallback(
    (device: PhyhubDevice) => {
      Modal.confirm({
        title: t('phyhubDevices.modal.rebootDevice.title'),
        cancelText: t('phyhubDevices.modal.rebootDevice.cancelText'),
        okText: t('phyhubDevices.modal.rebootDevice.okText'),
        content: (
          <Trans
            i18nKey="phyhubDevices.modal.rebootDevice.content"
            values={{
              deviceName: device.displayName,
            }}
            components={{ strong: <strong /> }}
          />
        ),
        onOk: handleDeviceRebootConfirm(device.id),
      });
    },
    [handleDeviceRebootConfirm, t],
  );

  return {
    handleDeviceReboot,
    isDeviceRebootLoading: isPhyhubDevicesRebootLoading,
  };
};

export default usePhyhubDeviceRebootFlow;
