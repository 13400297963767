import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Row, Col, Form, DatePicker, Tooltip, Icon } from 'antd';
import moment from 'moment';
import { PhyhubDeviceVersion } from '../../../../../../services/phyhub/types/phyhub-device-version.interface';

interface PhyhubDevicesUpdateOsControlsProps {
  plannedTimestamp: moment.Moment;
  targetVersion: string;
  availableVersions: PhyhubDeviceVersion[];
  onPlannedTimestampChange: (value: moment.Moment) => void;
  onTargetVersionChange: (value: string) => void;
}

const PhyhubDevicesUpdateOsControls: FC<PhyhubDevicesUpdateOsControlsProps> = (props) => {
  const {
    availableVersions,
    plannedTimestamp,
    targetVersion,
    onPlannedTimestampChange,
    onTargetVersionChange,
  } = props;

  const { t } = useTranslation();

  const handleTargetVersionChange = useCallback(
    (value: string) => {
      onTargetVersionChange(value);
    },
    [onTargetVersionChange],
  );

  const handleDateTimeChange = useCallback(
    (value: moment.Moment | null) => {
      if (value) {
        onPlannedTimestampChange(value);
      }
    },
    [onPlannedTimestampChange],
  );

  return (
    <Form layout="vertical">
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={12} xl={14}>
          <Form.Item
            label={
              <span>
                {t('phyhubDevices.label.desiredOsVersion')}{' '}
                <Tooltip title={t('phyhubDevices.tooltip.desiredOsVersion')}>
                  <Icon type="info-circle" />
                </Tooltip>
              </span>
            }
          >
            <Select value={targetVersion} onChange={handleTargetVersionChange}>
              {availableVersions.map((deviceVersion) => (
                <Select.Option key={deviceVersion.version} value={deviceVersion.version}>
                  {deviceVersion.version} ({t('phyhubDevices.label.latest')})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} xl={10}>
          <Form.Item label={t('phyhubDevices.label.schedule')}>
            <DatePicker
              showTime
              value={plannedTimestamp}
              onChange={handleDateTimeChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PhyhubDevicesUpdateOsControls;
