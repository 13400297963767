import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Card, Divider, Typography } from 'antd';

const { Title } = Typography;

export enum FormBlockCardHeaderTheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

interface FormBlockCardProps {
  header?: {
    title: string;
    theme: FormBlockCardHeaderTheme;
  };
}

const FormBlockCard: FC<FormBlockCardProps> = (props) => {
  const { children, header } = props;

  return (
    <Container>
      {!!header && (
        <>
          <Header theme={header.theme}>
            <Title level={header.theme === FormBlockCardHeaderTheme.SECONDARY ? 4 : 3}>
              {header.title}
            </Title>
          </Header>
          {header.theme === FormBlockCardHeaderTheme.PRIMARY && <StyledDivider />}
        </>
      )}
      <Content>{children}</Content>
    </Container>
  );
};

const Container = styled(Card)`
  position: relative;
  margin: 0 0 20px;
  padding: 20px;

  .ant-card-body {
    padding: 0;
  }
`;

const Header = styled.div<{ theme: FormBlockCardHeaderTheme }>`
  position: relative;
  margin: ${({ theme }) =>
    theme === FormBlockCardHeaderTheme.SECONDARY ? '0 0 20px' : '0'};
`;

const StyledDivider = styled(Divider)`
  margin: 24px -20px;
  width: calc(100% + 40px);
`;

const Content = styled.div`
  position: relative;
`;

export default FormBlockCard;
