import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { ColumnProps, TableRowSelection } from 'antd/lib/table';
import { PhyhubDevice } from '../../../../services/phyhub/types/phyhub-device.interface';

interface PhyhubDevicesPreviewTableProps {
  devices: PhyhubDevice[];
  rowSelection?: TableRowSelection<PhyhubDevice>;
}

const PhyhubDevicesPreviewTable: FC<PhyhubDevicesPreviewTableProps> = (props) => {
  const { devices, rowSelection } = props;

  const { t } = useTranslation();

  const columns = useMemo<ColumnProps<PhyhubDevice>[]>(
    () => [
      {
        key: 'displayName',
        title: t('device'),
        dataIndex: 'displayName',
        sorter: (a, b) => a.displayName.localeCompare(b.displayName),
      },
      // TODO: Add columns related to device updates
    ],
    [t],
  );

  return (
    <Table<PhyhubDevice>
      pagination={false}
      rowKey="id"
      size="small"
      dataSource={devices}
      columns={columns}
      rowSelection={rowSelection}
    />
  );
};

export default PhyhubDevicesPreviewTable;
