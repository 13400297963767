import React, { useCallback, useMemo, useState } from 'react';
import { message, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { DataMatrixCard } from '@ombori/grid-reports';
import { Link } from 'react-router-dom';
import CardContainer from '../card-container';
import { prepareDateRangeSearchParams } from '../../utils';
import {
  AnalyticsSchemaAwareReportForDataMatrixSpaceSection,
  DataMatrixDataForSpaceSection,
} from '../../../use-tenant-analytics-report/types';
import CardTitleDownloadButtonWrapper from '../card-title-download-button-wrapper';
import { TitleWithSelect, TitleWithSelectProps } from '../data-matrix/common/data-matrix-common.component';
import Message from '../../../message';
import downloadCSV from '../../../../../utils/download-csv';
import { convertToCSVFromObject, getCsvDataFileName } from '../../../../../utils/convert-csv';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';

interface AnalyticsReportDataMatrixSpaceSectionCardProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  card: DataMatrixCard;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: AnalyticsSchemaAwareReportForDataMatrixSpaceSection | null;
}

const AnalyticsReportDataMatrixSpaceSectionCard = (
  props: AnalyticsReportDataMatrixSpaceSectionCardProps,
) => {
  const { tenantId, dateFrom, dateTo, card, isLoading, isSuccess, isError, data } = props;

  const { t } = useTranslation();
  
  const [selectedSpaceId, setSelectedSpaceId] = useState<string | undefined>('');
  const [isDownloading, setIsDownloading] = useState(false);

  const tableColumnsData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.cardData.tableColumnsData;
  }, [data]);

  // filter results which don't have parent space and only contain one device
  const tableColumnsDataWithParentSpaceAndDevice = useMemo(() => {
    return tableColumnsData.filter((tableColumnData) => {
      const hasSingleDevice = tableColumnData.devices.length === 1;
      return tableColumnData.parentSpace && hasSingleDevice;
    });
  }, [tableColumnsData]);

  const tableColumnsDataFiltered = useMemo(() => {
    if (selectedSpaceId && selectedSpaceId !== 'all') {
      return tableColumnsDataWithParentSpaceAndDevice.filter((tableColumnData) => tableColumnData.id === selectedSpaceId);
    }

    return tableColumnsDataWithParentSpaceAndDevice;
  }, [tableColumnsDataWithParentSpaceAndDevice, selectedSpaceId]);

  const downloadFileColumnsData = useMemo(() => {
    if (!data) {
      return [];
    }
  
    return data.cardData.downloadFileColumnsData.filter((downloadFileColumnData) => {
      const hasSingleDevice = downloadFileColumnData.devices.length === 1;
      return downloadFileColumnData.parentSpace && hasSingleDevice;
    });
  }, [data]);

  const searchParams = useMemo(() => {
    return prepareDateRangeSearchParams(dateFrom, dateTo);
  }, [dateFrom, dateTo]);

  const spaceOptions = useMemo<TitleWithSelectProps['items']>(() => {
    return tableColumnsDataWithParentSpaceAndDevice.map(({ id, displayName }) => {
      return {
        key: id,
        label: displayName,
      }
    });
  }, [tableColumnsDataWithParentSpaceAndDevice]);

  const onSelectSpace = useCallback((spaceId?: string) => {
    setSelectedSpaceId(spaceId);
  }, []);

  const handleDownloadButtonClick = useCallback(() => {
    try {
      setIsDownloading(true);

      const title = card.title || 'Data Matrix Space';
      const dataExportFileName = getCsvDataFileName({ dateFrom, dateTo, title });

        const columns = downloadFileColumnsData.map((downloadFileColumnData) => {
        const baseColumns = {
          'Date from': dateFrom,
          'Date to': dateTo,
        }

        const row = {
          ...baseColumns,
          'Subspace': downloadFileColumnData.displayName,
          'Parent space': downloadFileColumnData.parentSpace ? downloadFileColumnData.parentSpace.name : '',
          'Device name': downloadFileColumnData.devices.length === 1 ? downloadFileColumnData.devices[0].name : '',
          ...downloadFileColumnData.dataColumns,
        };

        return row;
      });

      const csvData = convertToCSVFromObject(columns as Record<string, string>[]);

      if (csvData === '') {
        message.warning(<Message content={t('fileDownload.noDataToDownload')} />);
      } else {
        downloadCSV(csvData, dataExportFileName ? dataExportFileName : 'download-report.csv');
        message.success(<Message content={t('fileDownload.downloadStart')} />);
      }
    } catch (error) {
      Sentry.captureException(error);
      message.error(<Message content={(t('fileDownload.downloadFailure'))} />);
    } finally {
      setIsDownloading(false);
    }
  }, [card, downloadFileColumnsData, dateFrom, dateTo, t]);

  const tableColumns = useMemo<ColumnProps<DataMatrixDataForSpaceSection>[]>(() => {
    // get first element from tableColumns to get dynamic columns (dataColumns)
    // as all the records have the same columns regardless of whether they've data or not
    const firstRowDataColumns = tableColumnsDataFiltered[0]
      ? tableColumnsDataFiltered[0].dataColumns
      : {};

    const dataColumnsForTable = Object.keys(firstRowDataColumns).map<
      ColumnProps<DataMatrixDataForSpaceSection>
    >((columnKey, index) => {
      return {
        key: `${columnKey}:${index}`,
        title: columnKey,
        sorter: (source, target) => {
          const sourceValue = source.dataColumns[columnKey];
          const targetValue = target.dataColumns[columnKey];

          if (typeof sourceValue === 'number' && typeof targetValue === 'number') {
            return sourceValue - targetValue;
          } else {
            return (sourceValue as string).localeCompare(targetValue as string);
          }
        },
        render: (_, record) => {
          return record.dataColumns[columnKey];
        },
      };
    });

    return [
      {
        key: 'space-section',
        title: 'Subspace',
        sorter: (source, target) => {
          return source.displayName.localeCompare(target.displayName);
        },
        render: (_, record) => {
          return (
            <Link
              key={`space-section-link-${record.id}`}
              to={{
                pathname: `/organisations/${tenantId}/spaces/${record.id}`,
                search: `${searchParams}`,
              }}
            >
              {record.displayName}
            </Link>
          );
        },
      },
      {
        key: 'space-section-parent',
        title: 'Parent Space',
        sorter: (source, target) => {
          return source.displayName.localeCompare(target.displayName);
        },
        render: (_, record) => {
          return record.parentSpace ? (
            <Link
              key={`space-section-parent-link-${record.id}-${record.parentSpace.id}`}
              to={{
                pathname: `/organisations/${tenantId}/spaces/${record.parentSpace.id}`,
                search: `${searchParams}`,
              }}
            >
              {record.parentSpace.name}
            </Link>
          ) : (
            <></>
          );
        },
      },
      {
        key: 'space-section-device',
        title: 'Device',
        sorter: (source, target) => {
          return source.displayName.localeCompare(target.displayName);
        },
        render: (_, record) => {
          return record.devices.length === 1 ? (
            <Link
              key={`space-section-device-link-${record.id}`}
              to={{
                pathname: `/organisations/${tenantId}/operations/devices/v3/${record.devices[0].id}/overview`,
                search: `${searchParams}`,
              }}
            >
              {record.devices[0].name}
            </Link>
          ) : (
            <></>
          );
        },
      },
      ...dataColumnsForTable,
    ];
  }, [tableColumnsDataFiltered, searchParams, tenantId]);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={tableColumnsDataFiltered.length ? true : false}
      title={(
        <CardTitleDownloadButtonWrapper
          isDownloadButtonVisible={downloadFileColumnsData.length > 0}
          onDownloadButtonClicked={handleDownloadButtonClick}
          isDownloading={isDownloading}
        >
          {
            tableColumnsData.length > 0 ? (
              <TitleWithSelect title={card.title || ''} items={spaceOptions} onSelect={onSelectSpace} />
            ) : (
              <>{card.title}</>
            )
          }
        </CardTitleDownloadButtonWrapper>
      )}
      gridStyles={card.gridStyles}
      isVisibleWithoutData={card.isVisibleWithoutData}
    >
      <Table
        columns={tableColumns}
        dataSource={tableColumnsDataFiltered}
        size="middle"
        pagination={{
          pageSize: 5,
        }}
        scroll={{ x: 'max-content' }}
      />
    </CardContainer>
  );
};

export default AnalyticsReportDataMatrixSpaceSectionCard;
