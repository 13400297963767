import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import { BlockContainer } from '../../../../../common/phyhub-devices/phyhub-devices-common.components';
import PhyhubDevicesInstallationsList from '../../../../../common/phyhub-devices/phyhub-devices-installations-list/phyhub-devices-installations-list.component';
import OrganisationApp from '../../../../../../store/types/organisation-app';

interface PhyhubDeviceDetailsScreenInstallationsProps {
  tenantId: string;
  deviceInstallations: OrganisationApp[];
}

const PhyhubDeviceDetailsScreenInstallations: FC<PhyhubDeviceDetailsScreenInstallationsProps> = (
  props,
) => {
  const { tenantId, deviceInstallations } = props;

  return (
    <Wrapper>
      <Row gutter={{ md: 20, xl: 40 }}>
        <Col xs={24} xl={15}>
          <PhyhubDevicesInstallationsList
            tenantId={tenantId}
            installations={deviceInstallations}
          />
        </Col>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled(BlockContainer)`
  margin: 0 0 20px;

  @media screen and (min-width: 992px) {
    margin: 0 0 40px;
  }
`;

export default memo(PhyhubDeviceDetailsScreenInstallations);
