import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import { PhyhubDeviceListParams } from './types/phyhub-device-list-params.interface';
import { PhyhubDeviceGetParams } from './types/phyhub-device-get-params.interface';
import { PaginatedResponse } from './types/paginated-response.interface';
import { PhyhubDevice } from './types/phyhub-device.interface';
import { PhyhubDeviceBreakdownReport } from './types/phyhub-device-breakdown-report.interface';
import { PhyhubDeviceTenantParams } from './types/phyhub-device-tenant-params.interface';
import { PhyhubDevicesBulkUpdatePayload } from './types/phyhub-devices-bulk-update-payload.interface';
import { PhyhubDeviceOverviewParams } from './types/phyhub-device-overview-params.interface';
import { PhyhubDevicesDisconnectBody } from './types/phyhub-devices-disconnect-body.interface';
import { PhyhubDevicesConnectBody } from './types/phyhub-devices-connect-body.interface';
import { PhyhubDeviceCreateBody } from './types/phyhub-device-create-body.interface';
import { PhyhubDeviceUpdateBody } from './types/phyhub-device-update-body.interface';
import { PhyhubDevicesCommandBody } from './types/phyhub-devices-command-body.interface';

export class PhyhubService {
  private readonly apiClient: AxiosInstance;

  constructor(baseUrl: string) {
    this.apiClient = axios.create({
      baseURL: baseUrl,
      withCredentials: true,
    });
  }

  public async bulkUpdateDevices(
    params: PhyhubDeviceTenantParams,
    body: PhyhubDevicesBulkUpdatePayload,
  ): Promise<void> {
    const { tenantId } = params;

    await this.apiClient.post(
      `/api/v1/admin/tenant/${tenantId}/devices/bulk-update`,
      body,
    );
  }

  public async connectDevices(
    tenantId: string,
    body: PhyhubDevicesConnectBody,
  ): Promise<void> {
    await this.apiClient.post(`/api/v1/admin/tenant/${tenantId}/devices/connect`, body);
  }

  public async createDevice(
    tenantId: string,
    body: PhyhubDeviceCreateBody,
  ): Promise<PhyhubDevice> {
    const response = await this.apiClient.post(
      `/api/v1/admin/tenant/${tenantId}/devices`,
      body,
    );

    return response.data.data;
  }

  public async deleteDevice(params: PhyhubDeviceGetParams): Promise<void> {
    const { deviceId, tenantId } = params;

    await this.apiClient.delete(`/api/v1/admin/tenant/${tenantId}/devices/${deviceId}`);
  }

  public async disconnectDevices(
    params: PhyhubDeviceTenantParams,
    body: PhyhubDevicesDisconnectBody,
  ): Promise<void> {
    const { tenantId } = params;

    await this.apiClient.post(
      `/api/v1/admin/tenant/${tenantId}/devices/disconnect`,
      body,
    );
  }

  public async getDevice(params: PhyhubDeviceGetParams): Promise<PhyhubDevice> {
    const { deviceId, tenantId } = params;

    const response = await this.apiClient.get(
      `/api/v1/admin/tenant/${tenantId}/devices/${deviceId}`,
    );

    return response.data.data;
  }

  public async getDevicesOverview(
    params: PhyhubDeviceOverviewParams,
  ): Promise<PhyhubDeviceBreakdownReport> {
    const { tenantId, search, filters } = params;

    const filtersQueryString =
      filters && Object.keys(filters).length
        ? qs.stringify({ filters }, { arrayFormat: 'indices', encode: false })
        : undefined;

    const searchQueryString = search ? qs.stringify({ search }) : undefined;

    const queryString = [filtersQueryString, searchQueryString]
      .filter((value) => value != null)
      .join('&');

    const response = await this.apiClient.get(
      `/api/v1/admin/tenant/${tenantId}/devices/overview?${queryString}`,
    );

    return response.data.data;
  }

  public async listDevices(
    params: PhyhubDeviceListParams,
  ): Promise<PaginatedResponse<PhyhubDevice>> {
    const { limit, page, tenantId, search, filters, sort } = params;

    const filtersQueryString =
      filters && Object.keys(filters).length
        ? qs.stringify({ filters }, { arrayFormat: 'indices', encode: false })
        : undefined;

    const paginationQueryString = qs.stringify({ page, limit });

    const searchQueryString = search ? qs.stringify({ search }) : undefined;

    const sortQueryString = sort ? qs.stringify({ sort }) : undefined;

    const queryString = [
      paginationQueryString,
      filtersQueryString,
      searchQueryString,
      sortQueryString,
    ]
      .filter((value) => value != null)
      .join('&');

    const response = await this.apiClient.get(
      `/api/v1/admin/tenant/${tenantId}/devices?${queryString}`,
    );

    return response.data.data;
  }

  public async runCommand(
    params: PhyhubDeviceTenantParams,
    body: PhyhubDevicesCommandBody,
  ): Promise<void> {
    const { tenantId } = params;

    await this.apiClient.post(`/api/v1/admin/tenant/${tenantId}/devices/command`, body);
  }

  public async updateDevice(
    params: PhyhubDeviceGetParams,
    body: PhyhubDeviceUpdateBody,
  ): Promise<PhyhubDevice> {
    const { deviceId, tenantId } = params;

    const response = await this.apiClient.put(
      `/api/v1/admin/tenant/${tenantId}/devices/${deviceId}`,
      body,
    );

    return response.data.data;
  }
}
