import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import Message from '../../../message';
import usePhyhubDevicesDelete from '../../../../../store/hooks/phyhub-devices/use-phyhub-devices-delete';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import useDeleteModal from '../../../use-delete-modal';

interface UsePhyhubDeviceDeleteFlowParams {
  tenantId: string;
  onDeviceDeleteSuccess?: () => void;
}

interface UsePhyhubDeviceDeleteFlowReturn {
  isDeviceDeleteLoading: boolean;
  handleDeviceDelete: (device: PhyhubDevice) => void;
}

const usePhyhubDeviceDeleteFlow = (
  params: UsePhyhubDeviceDeleteFlowParams,
): UsePhyhubDeviceDeleteFlowReturn => {
  const { tenantId, onDeviceDeleteSuccess } = params;

  const { t } = useTranslation();

  const {
    isLoading: isPhyhubDeviceDeleteLoading,
    mutateAsync: phyhubDeviceDelete,
  } = usePhyhubDevicesDelete();

  const [showDeleteModal] = useDeleteModal();

  const handleDeviceDeleteConfirm = useCallback(
    (deviceId: string) => async () => {
      try {
        await phyhubDeviceDelete({ tenantId, deviceId });

        if (onDeviceDeleteSuccess) {
          onDeviceDeleteSuccess();
        }

        message.success(
          <Message content={t('phyhubDevices.details.message.deviceDeleteSuccess')} />,
        );
      } catch (error) {
        // TODO: Implement Phyhub errors parsing logic
        message.error(
          <Message content={t('phyhubDevices.details.message.deviceDeleteFailed')} />,
        );
      }
    },
    [tenantId, phyhubDeviceDelete, t, onDeviceDeleteSuccess],
  );

  const handleDeviceDelete = useCallback(
    (device: PhyhubDevice) => {
      showDeleteModal(
        t('confirmDeleteDevice'),
        t('areYouSureYouWantToDeleteDevice', {
          deviceName: device.displayName,
        }),
        device.displayName,
        handleDeviceDeleteConfirm(device.id),
      );
    },
    [handleDeviceDeleteConfirm, showDeleteModal, t],
  );

  return {
    handleDeviceDelete,
    isDeviceDeleteLoading: isPhyhubDeviceDeleteLoading,
  };
};

export default usePhyhubDeviceDeleteFlow;
