import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OrganisationSpace from '../../../../../../../store/types/organisation-space';
import Environment from '../../../../../../../store/types/environment';
import {
  Input,
  Option,
  Select,
  TextArea,
  validateByRegExPattern,
  validateRequired,
  validateTime,
  validateTimezone,
} from '../../../../../../common/react-final-form';
import { FieldContainer } from '../../../../../../common/phyhub-devices/phyhub-devices-common.components';
import { PHYHUB_DEVICE_TIMEZONE_OPTIONS } from '../../../../../../../store/types/phyhub-devices';

interface PhyhubDeviceDetailsFormProps {
  environments: Environment[];
  spaces: OrganisationSpace[];
}

const PhyhubDeviceDetailsFormFields: FC<PhyhubDeviceDetailsFormProps> = (props) => {
  const { environments, spaces } = props;

  const { t } = useTranslation();

  const environmentOptions = useMemo<Option[]>(
    () =>
      environments.map((env) => ({ label: env.displayName, value: env.environmentName })),
    [environments],
  );

  const spaceOptions = useMemo<Option[]>(
    () => spaces.map((space) => ({ label: space.displayName, value: space.id })),
    [spaces],
  );

  return (
    <>
      <FieldContainer>
        <Input isRequired readOnly label={t('deviceId')} name="deviceId" />
      </FieldContainer>
      <FieldContainer>
        <Input isRequired readOnly label={t('deviceSerial')} name="deviceSerial" />
      </FieldContainer>
      <FieldContainer>
        <Input
          isRequired
          label={t('displayName')}
          name="displayName"
          validators={[
            validateRequired(),
            validateByRegExPattern(
              '^([a-z][a-z0-9]*)(-[a-z0-9]+)*$',
              t('phyhubDevices.details.validation.displayName.invalid'),
            ),
          ]}
        />
      </FieldContainer>
      <FieldContainer>
        <Select
          isRequired
          isSearchEnabled
          label={t('environment')}
          name="env"
          options={environmentOptions}
          validators={[validateRequired()]}
        />
      </FieldContainer>
      <FieldContainer>
        <Select
          isRequired
          isSearchEnabled
          label={t('space')}
          name="spaceId"
          options={spaceOptions}
          validators={[validateRequired()]}
        />
      </FieldContainer>
      <FieldContainer>
        <Input label={t('externalId')} name="externalId" />
      </FieldContainer>
      <FieldContainer>
        <Select
          isRequired
          isSearchEnabled
          label={t('timezone')}
          name="timezone"
          options={PHYHUB_DEVICE_TIMEZONE_OPTIONS}
          validators={[
            validateRequired(),
            validateTimezone(t('formValidation.invalidTimezone')),
          ]}
        />
      </FieldContainer>
      <FieldContainer>
        <Input
          isRequired
          label={t('phyhubDevices.label.dailyRebootTime')}
          name="dailyRebootTime"
          validators={[validateRequired(), validateTime(t('formValidation.invalidTime'))]}
        />
      </FieldContainer>
      <FieldContainer>
        <TextArea label={t('notes')} name="notes" rows={4} />
      </FieldContainer>
    </>
  );
};

export default PhyhubDeviceDetailsFormFields;
