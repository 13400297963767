import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { message, Modal } from 'antd';
import PhyhubDevicesContainer from '../../../common/phyhub-devices/phyhub-devices.container';
import usePhyhubDeviceFilters from '../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-filters';
import usePhyhubDeviceSortOptions from '../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-sort-options';
import useEnvironments from '../../../common/use-environments/use-environments';
import { useSpaces } from '../../use-spaces';
import useInstallations from '../../../common/use-installation';
import { PhyhubDevice } from '../../../../services/phyhub/types/phyhub-device.interface';
import usePhyhubDevicesConnect from '../../../../store/hooks/phyhub-devices/use-phyhub-devices-connect';
import usePhyhubDevicesSearchAndFilters from '../hooks/use-phyhub-devices-search-and-filters';
import { PhyhubDeviceListParamsFiltersOperatorEnum } from '../../../../services/phyhub/types/phyhub-device-list-params.interface';
import usePhyhubDevicesDefaultTableColumns from '../hooks/use-phyhub-devices-default-table-columns';

interface OrganisationPhyhubDevicesContainerProps
  extends RouteComponentProps<{ organisationId: string; appId: string }> {
  onClose: () => void;
}

const PhyhubConnectDeviceContainer: FC<OrganisationPhyhubDevicesContainerProps> = (
  props,
) => {
  const {
    match: {
      params: { organisationId: tenantId, appId: installationId },
    },
    onClose,
  } = props;
  const { t } = useTranslation();

  const { data: environmentsCollection = [] } = useEnvironments(tenantId);

  const { data: installationsCollection = [] } = useInstallations(tenantId);

  const { data: spacesResponse } = useSpaces({ organisationId: tenantId });

  const { filterOptions } = usePhyhubDeviceFilters({
    environments: environmentsCollection,
    installations: installationsCollection.filter(
      (installation) => installation.id !== installationId,
    ),
    spaces: spacesResponse ? spacesResponse.spaces : [],
  });

  const { sortCriteriaOptions } = usePhyhubDeviceSortOptions();

  const { mutateAsync: phyhubDevicesConnect } = usePhyhubDevicesConnect();

  const handleDeviceClick = useCallback(
    (device: PhyhubDevice) => {
      const installation = installationsCollection.find(
        (installation) => installation.id === installationId,
      );
      return Modal.confirm({
        centered: true,
        title: t('confirmAction'),
        content: (
          <>
            <Message>
              <Trans
                i18nKey="deviceCurrentlyConnectedToDifferentInstallation"
                values={{ deviceName: device.displayName }}
                components={{ strong: <strong /> }}
              />
              {` ${t('onConnectMessage')}`}
            </Message>
            <Message>
              <Trans
                i18nKey="areYouSureYouWantToConnectDevice"
                values={{
                  deviceName: device.displayName,
                  appName: (installation && installation.appName) || '',
                }}
                components={{ strong: <strong /> }}
              />
            </Message>
          </>
        ),
        width: '30%',
        okText: t('confirm'),
        onOk: async () => {
          try {
            await phyhubDevicesConnect({
              tenantId,
              payload: {
                installationId,
                deviceIds: [device.id],
              },
            });
            message.success(t('successfullyConnectedDevice'));
            onClose();
          } catch (err) {
            message.error(t('failedToConnectDevice'));
          }
        },
        onCancel: () => {},
      });
    },
    [tenantId, installationId, installationsCollection, onClose, t, phyhubDevicesConnect],
  );

  const presetFilters = useMemo(
    () => ({
      installationIds: {
        op: PhyhubDeviceListParamsFiltersOperatorEnum.NOT_IN,
        val: [installationId],
      },
    }),
    [installationId],
  );

  const {
    searchPhrase,
    handleSetSearchPhrase,
    filters,
    handleFiltersChange,
  } = usePhyhubDevicesSearchAndFilters({ presetFilters });

  const { defaultTableColumns } = usePhyhubDevicesDefaultTableColumns({
    deviceActionItems: [],
    onDeviceClick: handleDeviceClick,
  });

  const deviceConnectDeviceListColumns = defaultTableColumns.filter(
    (column) => column.key !== 'serialNumber',
  );

  return (
    <PhyhubDevicesContainer
      tenantId={tenantId}
      searchPhrase={searchPhrase}
      filterOptions={filterOptions}
      filters={filters}
      sortCriteriaOptions={sortCriteriaOptions}
      tableColumns={deviceConnectDeviceListColumns}
      onFiltersChange={handleFiltersChange}
      onSearch={handleSetSearchPhrase}
    />
  );
};

const Message = styled.div`
  padding: 12px 0;
`;

export default PhyhubConnectDeviceContainer;
