import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePhyhubDevicesUpdateOsFlow from './phyhub-devices-mass-actions/use-phyhub-devices-update-os-flow';
import usePhyhubDevicesUpdateEnvironmentFlow from './phyhub-devices-mass-actions/use-phyhub-devices-update-environment-flow';
import usePhyhubDevicesRebootFlow from './phyhub-devices-mass-actions/use-phyhub-devices-reboot-flow';
import usePhyhubDevicesRestartFlow from './phyhub-devices-mass-actions/use-phyhub-devices-restart-flow';
import { PhyhubDevicesMassAction } from '../phyhub-devices-list/phyhub-devices-table/phyhub-devices-table-mass-actions/phyhub-devices-table-mass-actions.component';

interface UsePhyhubDevicesDefaultMassActionParams {
  tenantId: string;
  onResetSelectedDevices: () => void;
}

interface UsePhyhubDevicesDefaultMassActionReturn {
  isMassActionsLoading: boolean;
  defaultDeviceMassActionItems: PhyhubDevicesMassAction[];
}

const usePhyhubDevicesDefaultMassActions = (
  params: UsePhyhubDevicesDefaultMassActionParams,
): UsePhyhubDevicesDefaultMassActionReturn => {
  const { tenantId, onResetSelectedDevices } = params;

  const { t } = useTranslation();

  const handleMassActionSuccess = useCallback(() => {
    onResetSelectedDevices();
  }, [onResetSelectedDevices]);

  const { handleUpdateOs } = usePhyhubDevicesUpdateOsFlow({
    tenantId,
    onMassActionSuccess: handleMassActionSuccess,
  });

  const {
    isPhyhubDeviceUpdateEnvironmentLoading,
    handleUpdateEnvironment,
  } = usePhyhubDevicesUpdateEnvironmentFlow({
    tenantId,
    onMassActionSuccess: handleMassActionSuccess,
  });

  const { handleReboot } = usePhyhubDevicesRebootFlow({
    tenantId,
    onMassActionSuccess: handleMassActionSuccess,
  });

  const { handleRestart } = usePhyhubDevicesRestartFlow({
    tenantId,
    onMassActionSuccess: handleMassActionSuccess,
  });

  const handleUpdatesCancel = useCallback(() => {
    onResetSelectedDevices();
  }, [onResetSelectedDevices]);

  const deviceMassActionItems = useMemo<PhyhubDevicesMassAction[]>(
    () => [
      {
        key: 'phyhubDevicesUpdateOs',
        icon: 'cloud-upload',
        title: t('phyhubDevices.button.updateOs'),
        type: 'default',
        onClick: handleUpdateOs,
      },
      {
        key: 'phyhubDevicesUpdateEnvironment',
        icon: 'cloud',
        title: t('phyhubDevices.button.setEnvironment'),
        type: 'default',
        onClick: handleUpdateEnvironment,
      },
      {
        key: 'phyhubDevicesRestart',
        icon: 'reload',
        title: t('phyhubDevices.button.restart'),
        type: 'default',
        onClick: handleRestart,
      },
      {
        key: 'phyhubDevicesReboot',
        icon: 'poweroff',
        title: t('phyhubDevices.button.reboot'),
        type: 'default',
        onClick: handleReboot,
      },
      {
        key: 'phyhubDevicesCancelUpdates',
        title: t('phyhubDevices.button.cancelUpdates'),
        type: 'danger',
        onClick: handleUpdatesCancel,
      },
    ],
    [
      t,
      handleUpdateOs,
      handleUpdateEnvironment,
      handleRestart,
      handleReboot,
      handleUpdatesCancel,
    ],
  );

  return {
    isMassActionsLoading: isPhyhubDeviceUpdateEnvironmentLoading,
    defaultDeviceMassActionItems: deviceMassActionItems,
  };
};

export default usePhyhubDevicesDefaultMassActions;
