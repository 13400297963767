import React, { createContext, FC, useCallback, useContext, useState } from 'react';

export interface ModalParams {
  key: string;
  node: React.ReactNode;
}

interface ModalsManagerContextValue {
  addModal: (modalParams: ModalParams) => void;
  removeModal: (modalKey: string) => void;
}

const ModalsManagerContext = createContext<ModalsManagerContextValue | null>(null);

interface ModalsManagerProviderRenderProps {
  modals: ModalParams[];
}

interface ModalsManagerProviderProps {
  children: (renderProps: ModalsManagerProviderRenderProps) => React.ReactNode;
}

const ModalsManagerProvider: FC<ModalsManagerProviderProps> = (props) => {
  const { children } = props;

  const [modals, setModals] = useState<ModalParams[]>([]);

  const addModal = useCallback((modal: ModalParams) => {
    setModals((prevModals: ModalParams[]): ModalParams[] => {
      if (
        !prevModals.some((existingModal: ModalParams) => existingModal.key === modal.key)
      ) {
        return [...prevModals, modal];
      }

      return prevModals;
    });
  }, []);

  const removeModal = useCallback((modalKey: string) => {
    setModals((prevModals: ModalParams[]) =>
      prevModals.filter((modal) => modal.key !== modalKey),
    );
  }, []);

  return (
    <ModalsManagerContext.Provider value={{ addModal, removeModal }}>
      {children({ modals })}
    </ModalsManagerContext.Provider>
  );
};

export const useModalsManager = () => {
  const ctx = useContext(ModalsManagerContext);

  if (!ctx) {
    throw new Error('useModalsManager must be used within a ModalsManagerProvider');
  }

  return ctx;
};

export default ModalsManagerProvider;
