import React, { useCallback } from 'react';
import { message, Modal } from 'antd';
import usePhyhubDevicesDisconnect from '../../../../../store/hooks/phyhub-devices/use-phyhub-devices-disconnect';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import { Trans, useTranslation } from 'react-i18next';
import OrganisationApp from '../../../../../store/types/organisation-app';

interface UsePhyhubDeviceDisconnectFlowParams {
  tenantId: string;
  installation: OrganisationApp | null;
}

interface UsePhyhubDeviceDisconnectFlowReturn {
  isDeviceDisconnectLoading: boolean;
  handleDeviceDisconnect: (device: PhyhubDevice) => void;
}

const usePhyhubDeviceDisconnectFlow = (
  params: UsePhyhubDeviceDisconnectFlowParams,
): UsePhyhubDeviceDisconnectFlowReturn => {
  const { tenantId, installation } = params;

  const {
    isLoading: isPhyhubDeviceDisconnectLoading,
    mutateAsync: phyhubDevicesDisconnect,
  } = usePhyhubDevicesDisconnect();

  const { t } = useTranslation();

  const handleDeviceDisconnectConfirm = useCallback(
    (deviceId: string, installationId: string) => async () => {
      try {
        await phyhubDevicesDisconnect({
          tenantId,
          payload: { deviceIds: [deviceId], installationId },
        });

        message.success(t('successfullyDisconnectedDevice'));
      } catch {
        message.error(t('failedToDisconnectDevice'));
      }
    },
    [tenantId, phyhubDevicesDisconnect, t],
  );

  const handleDeviceDisconnect = useCallback(
    (device: PhyhubDevice) => {
      if (!installation) {
        return;
      }

      Modal.confirm({
        title: t('confirmAction'),
        cancelText: t('cancel'),
        okText: t('ok').toUpperCase(),
        content: (
          <Trans
            i18nKey="areYouSureYouWantToDisconnectDevice"
            values={{
              deviceName: device.displayName,
              appName: installation.appName,
            }}
            components={{ strong: <strong /> }}
          />
        ),
        onOk: handleDeviceDisconnectConfirm(device.id, installation.id),
      });
    },
    [handleDeviceDisconnectConfirm, installation, t],
  );

  return {
    handleDeviceDisconnect,
    isDeviceDisconnectLoading: isPhyhubDeviceDisconnectLoading,
  };
};

export default usePhyhubDeviceDisconnectFlow;
