import React, { FC, useMemo } from 'react';
import useInstallations from '../../../../use-installation';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import PhyhubDevicesTableInstallationCell from './phyhub-devices-table-installation-cell.component';
import { Spin } from 'antd';

interface PhyhubDevicesTableInstallationCellContainerProps {
  tenantId: string;
  deviceInstallationIds: string[];
}

const PhyhubDevicesTableInstallationCellContainer: FC<PhyhubDevicesTableInstallationCellContainerProps> = (
  props,
) => {
  const { tenantId, deviceInstallationIds } = props;

  // TODO: Rewrite installations requesting logic to fetch specific installations by ids
  const {
    isLoading: isInstallationsFetching,
    data: installationsCollection = [],
  } = useInstallations(tenantId);

  const deviceInstallations = useMemo<OrganisationApp[]>(
    () =>
      installationsCollection.filter((installation) =>
        deviceInstallationIds.includes(installation.id),
      ),
    [deviceInstallationIds, installationsCollection],
  );

  if (isInstallationsFetching) {
    return <Spin size="small" />;
  }

  return (
    <PhyhubDevicesTableInstallationCell
      tenantId={tenantId}
      deviceInstallations={deviceInstallations}
    />
  );
};

export default PhyhubDevicesTableInstallationCellContainer;
