import * as React from 'react';
import { useActions, useStore } from 'easy-peasy';
import { GridLlmService, GridLlmServiceProvider } from '@ombori/grid-llm-react';
import OrganisationDetails from './organisation-details.component';
import { RootModel, RootState } from '../../../store/models/root.model';
import { useInstallationGroups } from '../../common/use-installation-groups';
import useAllSpaces from '../../common/use-all-spaces';
import ActiveTenantAllSpacesProvider from '../../common/spaces-provider/spaces-provider.component';
import PhyhubServiceProvider from '../../common/phyhub-service-provider/phyhub-service.provider';
import { PhyhubService } from '../../../services/phyhub/phyhub.service';
import ModalsManagerProvider from '../../common/modals-manager-provider/modals-manager.provider';
import { Fragment } from 'react';

type OrganisationDetailsContainerProps = Omit<
  React.ComponentProps<typeof OrganisationDetails>,
  | 'organisation'
  | 'fetchApps'
  | 'appsAndGroupsLoaded'
  | 'apps'
  | 'organisationsList'
  | 'installationGroups'
>;

const defaultPhyhubUrl = 'https://phyhub.eu.omborigrid.net';

const OrganisationDetailsContainer = (props: OrganisationDetailsContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
  } = props;
  const [gridLlmServiceInstance, setGridLlmServiceInstance] = React.useState<
    GridLlmService
  >(
    new GridLlmService({
      dataResidency: 'eu',
    }),
  );

  const [phyhubServiceInstance, setPhyhubServiceInstance] = React.useState<PhyhubService>(
    new PhyhubService(defaultPhyhubUrl),
  );

  const installationGroupsQuery = useInstallationGroups({
    organisationId,
  });
  const installationGroups = installationGroupsQuery.data || [];

  const { organisationsList, organisation, appsLoaded, apps, user } = useStore<RootState>(
    ({ organisationApps, organisations, auth }) => ({
      organisationsList: organisations.values,
      organisation: organisations.data && organisations.data[organisationId],
      appsLoaded: organisationApps.loaded,
      apps: organisationApps.values(organisationId),
      user: auth.user,
      permissions: auth.permissions,
    }),
    [organisationId],
  );

  const spacesState = useAllSpaces(organisationId);

  const appsAndGroupsLoaded =
    appsLoaded && !installationGroupsQuery.isLoading && spacesState.isFetched;

  const { fetchApps } = useActions<RootModel>((actions) => ({
    fetchApps: actions.organisationApps.fetch,
  }));

  // Retrieve tenant branding settings from organisationBranding model
  const { tenantSettings, isLoading: isBrandingLoading, error: brandingError } = useStore<
    RootState
  >((state) => state.organisationBranding);

  const { fetchTenantSettings } = useActions<RootModel>(
    (actions) => actions.organisationBranding,
  );

  // Combine the loaded states
  const allDataLoaded = appsAndGroupsLoaded && !isBrandingLoading;

  // Fetch tenant settings on component mount or when organisationId changes
  React.useEffect(() => {
    if (organisationId) {
      fetchTenantSettings({
        tenantId: organisationId,
      });
    }
  }, [organisationId, fetchTenantSettings]);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setGridLlmServiceInstance(
        new GridLlmService({
          dataResidency: 'local',
        }),
      );
      return;
    }

    if (organisation) {
      setGridLlmServiceInstance(
        new GridLlmService({
          dataResidency: organisation.dataResidency,
        }),
      );
      return;
    }
  }, [organisation]);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setPhyhubServiceInstance(new PhyhubService('http://localhost:3303'));
      return;
    }

    if (organisation) {
      setPhyhubServiceInstance(
        new PhyhubService(organisation.phyhubUrl || defaultPhyhubUrl),
      );
      return;
    }
  }, [organisation]);

  return (
    <PhyhubServiceProvider service={phyhubServiceInstance}>
      <GridLlmServiceProvider gridLlmService={gridLlmServiceInstance}>
        <ActiveTenantAllSpacesProvider
          tenantId={organisationId}
          isLoading={spacesState.isLoading}
          isSuccess={spacesState.isSuccess}
          isError={spacesState.isError}
          spaces={spacesState.data ? spacesState.data : []}
          total={spacesState.data ? spacesState.data.length : 0}
        >
          <ModalsManagerProvider>
            {({ modals }) => (
              <>
                <OrganisationDetails
                  appsAndGroupsLoaded={allDataLoaded}
                  fetchApps={fetchApps}
                  organisation={organisation}
                  organisationsList={organisationsList}
                  apps={apps}
                  installationGroups={installationGroups}
                  user={user}
                  spaces={spacesState.data ? spacesState.data : []}
                  tenantSettings={tenantSettings}
                  {...props}
                />
                {modals.map((modal) => (
                  <Fragment key={modal.key}>{modal.node}</Fragment>
                ))}
              </>
            )}
          </ModalsManagerProvider>
        </ActiveTenantAllSpacesProvider>
      </GridLlmServiceProvider>
    </PhyhubServiceProvider>
  );
};

export default OrganisationDetailsContainer;
