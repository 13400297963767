import { useQuery } from 'react-query';
import phyhubDevicesQueryKeys from './phyhub-devices-query-keys';
import {
  PhyhubDeviceListParamsFilters,
  PhyhubDeviceListParamsSort,
} from '../../../services/phyhub/types/phyhub-device-list-params.interface';
import { usePhyhubService } from '../../../app/common/phyhub-service-provider/phyhub-service.provider';

export interface PhyhubDevicesListQueryParams {
  tenantId: string;
  page?: number;
  limit?: number;
  search?: string;
  filters?: PhyhubDeviceListParamsFilters;
  sort?: PhyhubDeviceListParamsSort;
}

const usePhyhubDevicesList = (phyhubDevicesListParams: PhyhubDevicesListQueryParams) => {
  const phyhubService = usePhyhubService();

  return useQuery(
    phyhubDevicesQueryKeys.getPhyhubDevicesListKeys(phyhubDevicesListParams),
    () =>
      phyhubService.listDevices({
        tenantId: phyhubDevicesListParams.tenantId,
        page: phyhubDevicesListParams.page,
        limit: phyhubDevicesListParams.limit,
        search: phyhubDevicesListParams.search,
        filters: phyhubDevicesListParams.filters,
        sort: phyhubDevicesListParams.sort,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default usePhyhubDevicesList;
